import Vue from "vue";
import Vuex from "vuex";
import jwtInterceptor from "@/shared/jwtInterceptor";
import { Cycle } from "@/interfaces/Cycle";
import { Cuts } from "@/interfaces/Cuts";
import {
  GoldCollector,
  Leader,
  Raid,
  SheetCustomer,
  SheetShift,
  SheetShiftString,
  Shift,
} from "@/interfaces/Raids";
import {
  runs,
  roles,
  user,
  goldCollectors,
  leaders,
  cycleData,
} from "@/helpers/fakeData";
import moment from "moment";
import helper from "@/helpers/helper";
import {
  PurchaseHistoryEntry,
  loyaltysystem,
} from "@/interfaces/LoyaltySystem";
import { LoyaltyPointsRequest } from "@/interfaces/LoyaltyPointsRequest";

Vue.use(Vuex);
const VUE_APP_OBLIVION_API = "https://api.oblivion-marketplace.com/v1/";

export default new Vuex.Store({
  state: {
    agreedToPrivacy: false,
    isAuthenticated: false,
    user: {} as any,
    loyaltySystem: {},
    cycleData: {},
    cycleDateInfo: {},
    cutsData: {},
    roles: [],
    goldCollectors: [] as Array<GoldCollector>,
    leaders: [] as Array<Leader>,
    raids: [] as Array<Raid>,
    mountRaids: [] as Array<Raid>,
    raidsOverview: [] as Array<Raid>,
    allRaidsForSchedule: [] as Array<Raid>,
    allMountRaidsForSchedule: [] as Array<Raid>,
    mountRaidsOverview: [] as Array<Raid>,
    raid: {
      interval: null,
    },
    raidOverview: {
      interval: null,
    },
    allRaidForSchedule: {
      interval: null,
    },
    allMountRaidForSchedule: {
      interval: null,
    },
    mountRaid: {
      interval: null,
    },
    mountRaidOverview: {
      interval: null,
    },
    shifts: [] as Array<Shift>,
    sheetShifts: [] as Array<SheetShift>,
    sheetCustomers: [] as Array<SheetCustomer>,
    sheetStrings: [] as Array<SheetShiftString>,
    shiftsOverview: [] as Array<Shift>,
    ordersForAdmin: [] as Array<PurchaseHistoryEntry>,
    shift: {
      interval: null,
    },
    sheetShift: {
      interval: null,
    },
    sheetCustomer: {
      interval: null,
    },
    sheetString: {
      interval: null,
    },
    shiftOverview: {
      interval: null,
    },
  },
  getters: {
    goldCollector:
      (state) =>
      (id): GoldCollector | any => {
        return (
          state.goldCollectors.find(
            (gc: GoldCollector) => gc.userID === id
          ) || {
            displayName: "No GC",
          }
        );
      },
    leader:
      (state) =>
      (id): Leader | any => {
        return (
          state.leaders.find((leader: Leader) => leader.userID === id) || {
            displayName: "No Leader",
          }
        );
      },
    getRaid:
      (state) =>
      (id): Raid | any => {
        return (
          state.raids.find((raid: Raid) => raid.id === id) ||
          state.raidsOverview.find((raid: Raid) => raid.id === id) ||
          state.allRaidsForSchedule.find((raid: Raid) => raid.id === id) ||
          null
        );
      },
    getMountRaid:
      (state) =>
      (id): Raid | any => {
        return (
          state.mountRaids.find((raid: Raid) => raid.id === id) ||
          state.mountRaidsOverview.find((raid: Raid) => raid.id === id) ||
          state.allMountRaidsForSchedule.find((raid: Raid) => raid.id === id) ||
          null
        );
      },
    getShift:
      (state) =>
      (id): Shift | any => {
        return state.shifts.find((shift: Shift) => shift.id === id) || null;
      },
    getSheetShift:
      (state) =>
      (id): SheetShift | any => {
        return (
          state.sheetShifts.find((shift: SheetShift) => shift.run === id) ||
          null
        );
      },
    getSheetCustomer:
      (state) =>
      (id): SheetShift | any => {
        return state.sheetCustomers || null;
      },
    getSheetStrings:
      (state) =>
      (id): SheetShiftString | any => {
        return state.sheetStrings || null;
      },
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage["cookie:accepted"]) {
        state.agreedToPrivacy = localStorage["cookie:accepted"];
      }
    },
    agreePrivacyPolicy(state) {
      localStorage.setItem("cookie:accepted", "true");
      state.agreedToPrivacy = true;
    },
    setUser(state, user) {
      state.user = user;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setCycleData(state, cycleData) {
      state.cycleData = cycleData;
    },
    setLoyaltySystem(state, loyaltyData) {
      state.loyaltySystem = loyaltyData;
    },
    setCutsData(state, cutsData) {
      state.cutsData = cutsData;
    },
    setCycleDateInfo(state, cycleData) {
      state.cycleDateInfo = cycleData;
    },
    setGoldCollectors(state, goldCollectors) {
      state.goldCollectors = goldCollectors;
    },
    setRaids(state, raids) {
      state.raids = raids;
    },
    setMountRaids(state, raids) {
      state.mountRaids = raids;
    },
    setShifts(state, raids) {
      state.shifts = raids;
    },
    setSheetShifts(state, raids) {
      state.sheetShifts = raids;
    },
    setSheetCustomers(state, raids) {
      state.sheetCustomers = raids;
    },
    setSheetStrings(state, raids) {
      state.sheetStrings = raids;
    },
    setLeaders(state, leaders) {
      state.leaders = leaders;
    },
    SET_RAID_INTERVAL(state, interval) {
      if (state.raid.interval) {
        clearInterval(state.raid.interval || 0);
      }
      state.raid.interval = interval;
    },
    SET_RAIDOVERVIEW_INTERVAL(state, interval) {
      if (state.raidOverview.interval) {
        clearInterval(state.raidOverview.interval || 0);
      }
      state.raidOverview.interval = interval;
    },
    SET_ALLRAIDSCHEDULE_INTERVAL(state, interval) {
      if (state.allRaidForSchedule.interval) {
        clearInterval(state.allRaidForSchedule.interval || 0);
      }
      state.allRaidForSchedule.interval = interval;
    },
    SET_ALLMOUNTRAIDSCHEDULE_INTERVAL(state, interval) {
      if (state.allMountRaidForSchedule.interval) {
        clearInterval(state.allMountRaidForSchedule.interval || 0);
      }
      state.allMountRaidForSchedule.interval = interval;
    },
    SET_MOUNT_RAID_INTERVAL(state, interval) {
      if (state.mountRaid.interval) {
        clearInterval(state.mountRaid.interval || 0);
      }
      state.mountRaid.interval = interval;
    },
    SET_MOUNT_RAIDOVERVIEW_INTERVAL(state, interval) {
      if (state.mountRaidOverview.interval) {
        clearInterval(state.mountRaidOverview.interval || 0);
      }
      state.mountRaidOverview.interval = interval;
    },
    SET_SHIFT_INTERVAL(state, interval) {
      if (state.shift.interval) {
        clearInterval(state.shift.interval || 0);
      }
      state.shift.interval = interval;
    },
    SET_SHEET_SHIFT_INTERVAL(state, interval) {
      if (state.sheetShift.interval) {
        clearInterval(state.sheetShift.interval || 0);
      }
      state.sheetShift.interval = interval;
    },
    SET_SHEET_CUSTOMER_INTERVAL(state, interval) {
      if (state.sheetCustomer.interval) {
        clearInterval(state.sheetCustomer.interval || 0);
      }
      state.sheetCustomer.interval = interval;
    },
    SET_SHEET_STRINGS_INTERVAL(state, interval) {
      if (state.sheetString.interval) {
        clearInterval(state.sheetString.interval || 0);
      }
      state.sheetString.interval = interval;
    },
    SET_SHIFTOVERVIEW_INTERVAL(state, interval) {
      if (state.shiftOverview.interval) {
        clearInterval(state.shiftOverview.interval || 0);
      }
      state.shiftOverview.interval = interval;
    },
    async CLEAR_API(state) {
      // If interval exists, clear it
      if (state.raid.interval) {
        clearInterval(state.raid.interval || 0);
      }
    },
    async CLEAR_API_RAIDSOVERVIEW(state) {
      // If interval exists, clear it
      if (state.raidOverview.interval) {
        clearInterval(state.raidOverview.interval || 0);
      }
    },
    async CLEAR_API_ALLRAIDSCHEDULE(state) {
      // If interval exists, clear it
      if (state.allRaidForSchedule.interval) {
        clearInterval(state.allRaidForSchedule.interval || 0);
      }
    },
    async CLEAR_API_ALLMOUNTRAIDSCHEDULE(state) {
      // If interval exists, clear it
      if (state.allMountRaidForSchedule.interval) {
        clearInterval(state.allMountRaidForSchedule.interval || 0);
      }
    },
    async CLEAR_API_MOUNTS(state) {
      // If interval exists, clear it
      if (state.mountRaid.interval) {
        clearInterval(state.mountRaid.interval || 0);
      }
    },
    async CLEAR_API_RAIDSOVERVIEW_MOUNTS(state) {
      // If interval exists, clear it
      if (state.mountRaidOverview.interval) {
        clearInterval(state.mountRaidOverview.interval || 0);
      }
    },
    async CLEAR_API_SHIFTS(state) {
      // If interval exists, clear it
      if (state.shift.interval) {
        clearInterval(state.shift.interval || 0);
      }
    },
    async CLEAR_API_SHEET_SHIFTS(state) {
      // If interval exists, clear it
      if (state.sheetShift.interval) {
        clearInterval(state.sheetShift.interval || 0);
      }
    },
    async CLEAR_API_SHEET_CUSTOMERS(state) {
      // If interval exists, clear it
      if (state.sheetCustomer.interval) {
        clearInterval(state.sheetCustomer.interval || 0);
      }
    },
    async CLEAR_API_SHEET_STRINGS(state) {
      // If interval exists, clear it
      if (state.sheetString.interval) {
        clearInterval(state.sheetString.interval || 0);
      }
    },
    async CLEAR_API_SHIFTSOVERVIEW(state) {
      // If interval exists, clear it
      if (state.shiftOverview.interval) {
        clearInterval(state.shiftOverview.interval || 0);
      }
    },
    SET_API_DATA(state, data) {
      state.raids = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_RAIDSOVERVIEW(state, data) {
      state.raidsOverview = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_ALLRAIDSCHEDULE(state, data) {
      state.allRaidsForSchedule = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_ALLMOUNTRAIDSCHEDULE(state, data) {
      state.allMountRaidsForSchedule = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_MOUNTS(state, data) {
      state.mountRaids = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_RAIDSOVERVIEW_MOUNTS(state, data) {
      state.mountRaidsOverview = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_SHIFTS(state, data) {
      state.shifts = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_SHEET_SHIFTS(state, data) {
      state.sheetShifts = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_SHEET_CUSTOMERS(state, data) {
      state.sheetCustomers = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_SHEET_STRINGS(state, data) {
      state.sheetStrings = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_SHIFTSOVERVIEW(state, data) {
      state.shiftsOverview = JSON.parse(JSON.stringify(data));
    },
    SET_API_DATA_ORDERS_FOR_ADMIN(state, data) {
      state.ordersForAdmin = JSON.parse(JSON.stringify(data));
    },
  },
  actions: {
    async clearAPIInterval({ commit }) {
      await commit("CLEAR_API");
      await commit("CLEAR_API_RAIDSOVERVIEW");
      await commit("CLEAR_API_ALLRAIDSCHEDULE");
      await commit("CLEAR_API_ALLMOUNTRAIDSCHEDULE");
      await commit("CLEAR_API_MOUNTS");
      await commit("CLEAR_API_RAIDSOVERVIEW_MOUNTS");
      await commit("CLEAR_API_SHIFTS");
      await commit("CLEAR_API_SHEET_SHIFTS");
      await commit("CLEAR_API_SHEET_CUSTOMERS");
      await commit("CLEAR_API_SHEET_STRINGS");
      await commit("CLEAR_API_SHIFTSOVERVIEW");
      return;
    },
    clearAPI({ commit }) {
      commit("CLEAR_API");
    },
    clearAPIShifts({ commit }) {
      commit("CLEAR_API_SHIFTS");
    },
    clearAPISheetShifts({ commit }) {
      commit("CLEAR_API_SHEET_SHIFTS");
    },
    clearAPISheetCustomers({ commit }) {
      commit("CLEAR_API_SHEET_CUSTOMERS");
    },
    clearAPISheetStrings({ commit }) {
      commit("CLEAR_API_SHEET_STRINGS");
    },
    async getDiscordUserDetails(context) {
      let userObject = {};
      // if (!VUE_APP_OBLIVION_API && false) {
      //     userObject = user;
      // } else {
      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + `/user/discord`
      );

      if (response) {
        userObject = response.data;
      }
      // }
      context.commit("setUser", userObject);
    },
    async getDiscordUserRoles(context) {
      let userRoles: Array<string> = [];
      // if (!VUE_APP_OBLIVION_API && false) {
      //     userRoles = roles;
      // } else {
      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + `/user/discord/roles`
      );

      if (response) {
        userRoles = response.data;
      }
      // }
      context.commit("setRoles", userRoles);
    },
    async getGoldCollectors(context) {
      const getData = () =>
        new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API && false) {
          //     setTimeout(() => {
          //         resolve(goldCollectors);
          //     }, 2000);
          // } else {
          jwtInterceptor
            .get(VUE_APP_OBLIVION_API + `/goldcollector`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
          // }
        });

      context.commit("setGoldCollectors", await getData());
    },
    async getLeaders(context) {
      const getData = () =>
        new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API && false) {
          //     setTimeout(() => {
          //         resolve(leaders);
          //     }, 2000);
          // } else {
          jwtInterceptor
            .get(VUE_APP_OBLIVION_API + `/leader`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
          // }
        });
      context.commit("setLeaders", await getData());
    },
    async isAuthenticated(context) {
      let authenticated = false;
      // if (!VUE_APP_OBLIVION_API && false) {
      //     authenticated = true;
      // } else {
      const response = await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/isAuthenticated")
        .catch(() => context.state.isAuthenticated);
      if (response) {
        authenticated = true;
      }
      // }

      context.commit("setIsAuthenticated", authenticated);
      return authenticated;
    },
    async logout(context) {
      await jwtInterceptor.get(VUE_APP_OBLIVION_API + "/authentication/revoke");
      context.commit("setIsAuthenticated", false);
    },
    async getCycleData(context) {
      let cycleDatas: Cycle = {} as Cycle;
      // if (!VUE_APP_OBLIVION_API && false) {
      //     cycleDatas = cycleData as Cycle;
      // } else {
      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + "/user/cycle"
      );

      if (response) {
        cycleDatas = response.data;
      }
      // }
      context.commit("setCycleData", cycleDatas);
    },
    async getCycleDataAsAdmin(context, discordId) {
      const response = await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/user/cycle/" + discordId)
        .catch(() => console.log("Forbidden"));
      if (response) {
        context.commit("setCycleData", response.data);
      }
    },
    async getLoyaltyData(context) {
      let loyaltyData: loyaltysystem = {} as loyaltysystem;

      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + "/loyaltysystem"
      );

      if (response) {
        loyaltyData = response.data;
      }
      context.commit("setLoyaltySystem", loyaltyData);
    },
    async getLoyaltyDataAsAdmin(context, discordId) {
      let loyaltyData: loyaltysystem = {} as loyaltysystem;

      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + "/loyaltysystem/" + discordId
      );

      if (response) {
        loyaltyData = response.data;
      }
      context.commit("setLoyaltySystem", loyaltyData);
    },
    async getCutsData(context) {
      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + "/cuts/config"
      );
      if (response) {
        context.commit("setCutsData", response.data);
      }
    },
    async getCycleDate(context) {
      const response = await jwtInterceptor.get(
        VUE_APP_OBLIVION_API + "/cycle/config"
      );
      if (response) {
        context.commit("setCycleDateInfo", response.data);
      }
    },
    async configureCycle(context, cycle: Cycle) {
      await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/cycle/config", cycle)
        .catch(() => console.log("Forbidden"));
    },
    async configureCuts(context, cuts: Cuts) {
      await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/cuts/config", cuts)
        .catch(() => console.log("Forbidden"));
    },
    async createManualLoyaltyRequest(
      context,
      loyaltypointsRequest: LoyaltyPointsRequest
    ) {
      await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + "/loyaltysystem/assign",
          loyaltypointsRequest
        )
        .catch(() => console.log("Forbidden"));
    },
    async createBooking(context, booking) {
      return await jwtInterceptor
        .post(VUE_APP_OBLIVION_API + `/booking/${booking.section}`, booking)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createRaid(context, raidDetails) {
      return await jwtInterceptor
        .post(VUE_APP_OBLIVION_API + `/raid/createRaid/`, raidDetails)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createMountRaid(context, raidDetails) {
      return await jwtInterceptor
        .post(VUE_APP_OBLIVION_API + `/mountraid/createRaid/`, raidDetails)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createShift(context, raidDetails) {
      return await jwtInterceptor
        .post(VUE_APP_OBLIVION_API + `/raid/createShift/`, raidDetails)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateRaid(context, raidDetails) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/raid/${raidDetails.id}/update`,
          raidDetails
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateMountRaid(context, raidDetails) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/mountraid/${raidDetails.id}/update`,
          raidDetails
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateRaidCustomer(context, raidDetails) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/raid/${raidDetails.id}/markCustomerDone`,
          raidDetails
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateShift(context, raidDetails) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/shift/${raidDetails.id}/update`,
          raidDetails
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateShiftCustomer(context, raidDetails) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/shift/${raidDetails.id}/markCustomerDone`,
          raidDetails
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getRaids(context, info) {
      context.commit("CLEAR_API");
      const getData = () => {
        return new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API) {
          //     resolve(runs);
          //     const params = helper.buildParams({
          //         ...info,
          //         start: info && info.start ? info.start : moment().tz("Europe/Berlin").subtract(1, "day").format("YYYY-MM-DD"),
          //         end: info && info.end ? info.end : moment().tz("Europe/Berlin").format("YYYY-MM-DD")
          //     });
          //     console.log(params);
          //     console.log("request mock - " + moment().format("H:mm:ss"));
          // } else {
          console.log("prod");
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(
              info.specificOnly
                ? `${VUE_APP_OBLIVION_API}/raid/getspecificraid/${
                    info.specificOnly
                  }?${params.toString()}`
                : `${VUE_APP_OBLIVION_API}/raid/getAllRaids?${params.toString()}`
            )
            .then((response) => {
              resolve(info.specificOnly ? [response.data] : response.data);
            })
            .catch((error) => {
              reject();
            });
          // }
        });
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_RAID_INTERVAL", interval);
      }
    },
    async getRaidsOverview(context, info) {
      context.commit("CLEAR_API_RAIDSOVERVIEW");
      const getData = () => {
        return new Promise(
          (resolve, reject) => {
            // if (!VUE_APP_OBLIVION_API && false) {
            //     resolve(runs)
            //     const params = helper.buildParams({
            //         ...info,
            //         start: info && info.start ? info.start : moment().tz("Europe/Berlin").subtract(1, "day").format("YYYY-MM-DD"),
            //         end: info && info.end ? info.end : moment().tz("Europe/Berlin").format("YYYY-MM-DD")})
            //     console.log(params);
            //     console.log("request mock overview - " + moment().format("H:mm:ss"));
            // } else {
            console.log("prod overview");
            const params = helper.buildParams({
              ...info,
              start:
                info && info.start
                  ? info.start
                  : moment()
                      .tz("Europe/Berlin")
                      .subtract(1, "day")
                      .format("YYYY-MM-DD"),
              end:
                info && info.end
                  ? info.end
                  : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
            });
            jwtInterceptor
              .get(
                VUE_APP_OBLIVION_API +
                  `/raid/getAllRaidsOverview?${params.toString()}`
              )
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject();
              });
          }
          // }
        );
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA_RAIDSOVERVIEW", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_RAIDOVERVIEW_INTERVAL", interval);
      }
    },
    async getMountRaids(context, info) {
      context.commit("CLEAR_API_MOUNTS");
      const getData = () => {
        return new Promise((resolve, reject) => {
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(
              info.specificOnly
                ? `${VUE_APP_OBLIVION_API}/mountraid/getspecificraid/${
                    info.specificOnly
                  }?${params.toString()}`
                : `${VUE_APP_OBLIVION_API}/mountraid/getAllRaids?${params.toString()}`
            )
            .then((response) => {
              resolve(info.specificOnly ? [response.data] : response.data);
            })
            .catch((error) => {
              reject();
            });
          // }
        });
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA_MOUNTS", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_MOUNT_RAID_INTERVAL", interval);
      }
    },
    async getMountRaidsOverview(context, info) {
      context.commit("CLEAR_API_RAIDSOVERVIEW_MOUNTS");
      const getData = () => {
        return new Promise(
          (resolve, reject) => {
            const params = helper.buildParams({
              ...info,
              start:
                info && info.start
                  ? info.start
                  : moment()
                      .tz("Europe/Berlin")
                      .subtract(1, "day")
                      .format("YYYY-MM-DD"),
              end:
                info && info.end
                  ? info.end
                  : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
            });
            jwtInterceptor
              .get(
                VUE_APP_OBLIVION_API +
                  `/mountraid/getAllRaidsOverview?${params.toString()}`
              )
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject();
              });
          }
          // }
        );
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA_RAIDSOVERVIEW_MOUNTS", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_MOUNT_RAIDOVERVIEW_INTERVAL", interval);
      }
    },
    async getShifts(context, info) {
      context.commit("CLEAR_API_SHIFTS");
      const getData = () => {
        return new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API && false) {
          //     resolve(shifts);
          //     const params = helper.buildParams({
          //         ...info,
          //         start: info && info.start ? info.start : moment().tz("Europe/Berlin").subtract(1, "day").format("YYYY-MM-DD"),
          //         end: info && info.end ? info.end : moment().tz("Europe/Berlin").format("YYYY-MM-DD")
          //     });
          //     console.log(params);
          //     console.log("request mock - " + moment().tz("Europe/Berlin").format("H:mm:ss"));
          // } else {
          console.log("prod");
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(
              info.specificOnly
                ? `${VUE_APP_OBLIVION_API}/shift/getspecificshift/${info.specificOnly}?${params}`
                : `${VUE_APP_OBLIVION_API}/raid/getAllShifts?${params}`
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
          // }
        });
      };
      const filterData = async () => {
        const shifts: Array<Shift> = (await getData()) as Array<Shift>;

        context.commit("SET_API_DATA_SHIFTS", shifts);
      };
      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_SHIFT_INTERVAL", interval);
      }
    },
    async getShiftsOverview(context, info) {
      context.commit("CLEAR_API_SHIFTSOVERVIEW");
      const getData = () => {
        return new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API && false) {
          //     resolve(shifts)
          //     const params = helper.buildParams({
          //         ...info,
          //         start: info && info.start ? info.start : moment().tz("Europe/Berlin").subtract(1, "day").format("YYYY-MM-DD"),
          //         end: info && info.end ? info.end : moment().tz("Europe/Berlin").format("YYYY-MM-DD")})
          //     console.log(params);
          //     console.log("request mock - " + moment().tz("Europe/Berlin").format("H:mm:ss"));
          // } else {
          console.log("prod");
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(VUE_APP_OBLIVION_API + `/raid/getAllShiftsOverview?${params}`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
          // }
        });
      };
      const filterData = async () => {
        const shifts: Array<Shift> = (await getData()) as Array<Shift>;

        context.commit("SET_API_DATA_SHIFTSOVERVIEW", shifts);
      };
      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_SHIFTOVERVIEW_INTERVAL", interval);
      }
    },
    async getSheetShifts(context, info) {
      context.commit("CLEAR_API_SHEET_SHIFTS");
      const getData = () => {
        return new Promise((resolve, reject) => {
          console.log("prod");
          jwtInterceptor
            .get(`${VUE_APP_OBLIVION_API}/shift/overview`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
        });
      };
      const filterData = async () => {
        const shifts: Array<SheetShift> =
          (await getData()) as Array<SheetShift>;
        context.commit("SET_API_DATA_SHEET_SHIFTS", shifts);
      };
      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_SHEET_SHIFT_INTERVAL", interval);
      }
    },
    async getSheetCustomer(context, info) {
      context.commit("CLEAR_API_SHEET_CUSTOMERS");
      const getData = () => {
        return new Promise((resolve, reject) => {
          console.log("prod");
          jwtInterceptor
            .get(`${VUE_APP_OBLIVION_API}/shift/customer`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
        });
      };
      const filterData = async () => {
        const customer: Array<SheetCustomer> =
          (await getData()) as Array<SheetCustomer>;
        context.commit("SET_API_DATA_SHEET_CUSTOMERS", customer);
      };
      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_SHEET_CUSTOMER_INTERVAL", interval);
      }
    },
    async getSheetStrings(context, info) {
      context.commit("CLEAR_API_SHEET_STRINGS");
      const getData = () => {
        return new Promise((resolve, reject) => {
          console.log("prod");
          jwtInterceptor
            .get(`${VUE_APP_OBLIVION_API}/shift/strings`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
        });
      };
      const filterData = async () => {
        const strings: Array<SheetShiftString> =
          (await getData()) as Array<SheetShiftString>;
        context.commit("SET_API_DATA_SHEET_STRINGS", strings);
      };
      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_SHEET_STRINGS_INTERVAL", interval);
      }
    },
    async bookCustomerInRaid(context, info) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/raid/` + info.raidId + "/book",
          info.customer
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async bookCustomerInMountRaid(context, info) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/mountraid/` + info.raidId + "/book",
          info.customer
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async deleteEmptyRaids(context, info) {
      return await jwtInterceptor
        .put(`${VUE_APP_OBLIVION_API}/raid/cleanup`, { date: "today" })
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateBooking(context, info) {
      return await jwtInterceptor
        .put(
          `${VUE_APP_OBLIVION_API}/raid/${
            info.raid.id ? info.raid.id : info.raid
          }/book/${info.booking.id}`,
          info.booking
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateMountBooking(context, info) {
      return await jwtInterceptor
        .put(
          `${VUE_APP_OBLIVION_API}/mountraid/${
            info.raid.id ? info.raid.id : info.raid
          }/book/${info.booking.id}`,
          info.booking
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createManagerBookings(context, info) {
      return await jwtInterceptor
        .put(
          `${VUE_APP_OBLIVION_API}/raid/${
            info.raid.id ? info.raid.id : info.raid
          }/createmanagerbookings`,
          info.amount
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async deleteBooking(context, info) {
      return await jwtInterceptor
        .delete(
          `${VUE_APP_OBLIVION_API}/raid/${info.raid}/book/${info.customer}`
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async bookCustomerInShift(context, info) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + `/shift/` + info.raidId + "/book",
          info.customer
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateShiftBooking(context, info) {
      return await jwtInterceptor
        .put(
          `${VUE_APP_OBLIVION_API}/shift/${info.raid}/book/${info.booking.bookingId}`,
          info.booking
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async deleteShiftBooking(context, info) {
      return await jwtInterceptor
        .delete(
          `${VUE_APP_OBLIVION_API}/shift/${info.raid}/book/${info.customer}`
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async updateShiftString(context, info) {
      return await jwtInterceptor
        .put(`${VUE_APP_OBLIVION_API}/shift/string/mailed`, info.booking)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getRaiderIoInfo(context, raiderIoLink) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/getCustomerData/", {
          params: {
            raiderIoLink,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getNameChangeLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/namechange", {
          params: {
            id: info.id || null,
            startDate: info.startDate || null,
            endDate: info.endDate || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getEditCustomerLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/updatecustomer", {
          params: {
            customerName: info.customerName || null,
            runId: info.runId || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getDeleteCustomerLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/deletedcustomer", {
          params: {
            customerName: info.customerName || null,
            runId: info.runId || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getBookedCustomerLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/bookedcustomer", {
          params: {
            customerName: info.customerName || null,
            runId: info.runId || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getEditRaidLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/editraid", {
          params: {
            discordId: info.discordId || null,
            runId: info.runId || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getCreateRaidLogs(context, info) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/logs/createraid", {
          params: {
            discordId: info.discordId || null,
            runId: info.runId || null,
          },
        })
        .catch((error) => ({ status: 500, message: error.message }));
    },
    async getRaidSchedule(context, raidOptions) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/raid/overview", {
          params: {
            day: raidOptions.day,
            type: raidOptions.type,
            faction: raidOptions.faction,
          },
        })
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getAllRaidsForSchedule(context, info) {
      context.commit("CLEAR_API_ALLRAIDSCHEDULE");
      const getData = () => {
        return new Promise((resolve, reject) => {
          // if (!VUE_APP_OBLIVION_API) {
          //     resolve(runs);
          //     const params = helper.buildParams({
          //         ...info,
          //         start: info && info.start ? info.start : moment().tz("Europe/Berlin").subtract(1, "day").format("YYYY-MM-DD"),
          //         end: info && info.end ? info.end : moment().tz("Europe/Berlin").format("YYYY-MM-DD")
          //     });
          //     console.log(params);
          //     console.log("request mock - " + moment().format("H:mm:ss"));
          // } else {
          console.log("prod");
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(
              `${VUE_APP_OBLIVION_API}/raid/getAllRaidsForSchedule?${params.toString()}`
            )
            .then((response) => {
              resolve(info.specificOnly ? [response.data] : response.data);
            })
            .catch((error) => {
              reject();
            });
          // }
        });
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA_ALLRAIDSCHEDULE", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_ALLRAIDSCHEDULE_INTERVAL", interval);
      }
    },
    async getAllMountRaidsForSchedule(context, info) {
      context.commit("CLEAR_API_ALLMOUNTRAIDSCHEDULE");
      const getData = () => {
        return new Promise((resolve, reject) => {
          console.log("prod");
          const params = helper.buildParams({
            ...info,
            start:
              info && info.start
                ? info.start
                : moment()
                    .tz("Europe/Berlin")
                    .subtract(1, "day")
                    .format("YYYY-MM-DD"),
            end:
              info && info.end
                ? info.end
                : moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
          });
          jwtInterceptor
            .get(
              `${VUE_APP_OBLIVION_API}/mountraid/getAllRaidsForSchedule?${params.toString()}`
            )
            .then((response) => {
              resolve(info.specificOnly ? [response.data] : response.data);
            })
            .catch((error) => {
              reject();
            });
        });
      };
      const filterData = async () => {
        const raids: Array<Raid> = (await getData()) as Array<Raid>;

        context.commit("SET_API_DATA_ALLMOUNTRAIDSCHEDULE", raids);
      };

      filterData();
      if (!info || (info && !info.noReload)) {
        const interval = setInterval(() => {
          filterData();
        }, 10000);
        context.commit("SET_ALLMOUNTRAIDSCHEDULE_INTERVAL", interval);
      }
    },
    async getMountRaidSchedule(context, raidOptions) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/mountraid/overview", {
          params: {
            day: raidOptions.day,
            type: raidOptions.type,
            faction: raidOptions.faction,
          },
        })
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async checkoutCart(context, data) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/store/checkout", {
          items: data.items,
          totalCosts: data.totalCosts,
        })
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getItems(context) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/loyaltysystemitems/")
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getItemsForAdmin() {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/loyaltysystemadminitems/")
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async saveItems(context, items) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/loyaltysystem/items", items)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getOrdersForAdmin(context, info) {
      const getData = () => {
        return new Promise((resolve, reject) => {
          jwtInterceptor
            .get(`${VUE_APP_OBLIVION_API}/loyaltysystemorders/`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject();
            });
        });
      };
      const filterData = async () => {
        const raids: Array<PurchaseHistoryEntry> =
          (await getData()) as Array<PurchaseHistoryEntry>;

        context.commit("SET_API_DATA_ORDERS_FOR_ADMIN", raids);
      };

      filterData();
    },
    async updateOrderStatus(context, updatedData) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/loyaltysystemorders/update", updatedData)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getProgramManagementData(context, raidOptions) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/programdata")
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async saveProgramManagementData(context, programEntries) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/programdata", programEntries)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getRecurringRaids(context, raidOptions) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/raid/getRecurringRaids")
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async saveRecurringRaids(context, recurringRaids) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/raid/saveRecurringRaids", recurringRaids)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createRecurringRaids(context, cycleDataAndFilter) {
      return await jwtInterceptor
        .put(
          VUE_APP_OBLIVION_API + "/raid/createRecurringRaids",
          cycleDataAndFilter
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getRecurringShifts(context, raidOptions) {
      return await jwtInterceptor
        .get(VUE_APP_OBLIVION_API + "/raid/getRecurringShifts")
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async saveRecurringShifts(context, recurringRaids) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/raid/saveRecurringShifts", recurringRaids)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async createRecurringShifts(context, cycleData) {
      return await jwtInterceptor
        .put(VUE_APP_OBLIVION_API + "/raid/createRecurringShifts", cycleData)
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },

    async getOwnRosters(context, data) {
      return await jwtInterceptor
        .get(
          `https://raidsignup.oblivion-marketplace.com/get-own-rosters/${data.userId}`,
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getRosters(context, data) {
      return await jwtInterceptor
        .get(`https://raidsignup.oblivion-marketplace.com/get-rosters`, {
          withCredentials: false,
        })
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async pickBooster(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/add/`,
          {
            role: data.role,
            boosterClass: data.boosterClass,
            state: data.state,
            booster: data.booster,
            raidId: data.raidId,
            user: context.state.user.id,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async unPickBooster(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/remove/`,
          {
            booster: data.booster,
            raidId: data.raidId,
            user: context.state.user.id,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async pingInvites(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/ping/`,
          {
            message: data.messageId,
            horde: data.hordeChar,
            alliance: data.allianceChar,
            custom: data.customText,
            voice: data.voiceChannel,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async editNote(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/note`,
          {
            message: data.messageId,
            leaderNote: data.leaderNote,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async urgentPing(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/urgent`,
          {
            message: data.message,
            raidId: data.raidId,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async setBoosterRole(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/role`,
          {
            raidId: data.raidId,
            booster: data.booster,
            charClass: data.charClass,
            role: data.role,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async setBoosterClass(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/character-class`,
          {
            raidId: data.raidId,
            booster: data.booster,
            oldClass: data.oldClass,
            newClass: data.newClass,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async setBoosterStatus(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/status`,
          {
            raidId: data.raidId,
            booster: data.booster,
            charClass: data.charClass,
            status: data.status,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async setRoleCount(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/role-count`,
          {
            message: data.message,
            tank: data.tank,
            heal: data.heal,
            dps: data.dps,
            lb: data.lb,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async deleteRoster(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/delete-roster`,
          {
            roster: data.roster,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async deleteRosterByRaid(context, data) {
      return await jwtInterceptor
        .post(
          `https://raidsignup.oblivion-marketplace.com/delete-raid-roster`,
          {
            raid: data.raidId,
            user: context.state.user.id,
          },
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
    async getLogs(context, data) {
      return await jwtInterceptor
        .get(
          `https://raidsignup.oblivion-marketplace.com/logs/${data.raidId}?page=${data.page}`,
          {
            withCredentials: false,
          }
        )
        .catch((err) => ({
          status: 500,
          message: err.message,
        }));
    },
  },
  modules: {},
});
