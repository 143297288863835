import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
import Boostee from "@/views/Boostee.vue";
import NotFound from "@/components/NotFound.vue";
import Callback from "@/components/Callback.vue";
import Booster from "@/views/Booster.vue";
import LoyaltySystem from "@/views/LoyaltySystem.vue";
import Administration from "@/views/Administration.vue";
import LogViewer from "@/views/LogViewer.vue";
import LoyaltyStoreForm from "@/components/loyaltysystem/LoyaltyStoreForm.vue";
import Booking from "@/views/Booking.vue";
import MythicPlus from "@/views/booking/MythicPlus.vue";
import Mythic from "@/views/booking/Mythic.vue";
import Leveling from "@/views/booking/Leveling.vue";
import Mounts from "@/views/booking/Mounts.vue";
import RaidManagement from "@/views/RaidManagement.vue";
import Pvp from "@/views/booking/Pvp.vue";
import Legacy from "@/views/booking/Legacy.vue";
import Terms from "@/views/legal/Terms.vue";
import RaidOverviewEntry from "@/views/booking/raid/RaidOverviewEntry.vue";
import Privacy from "@/views/legal/Privacy.vue";
import Cookies from "@/views/legal/Cookies.vue";
import RaidCreation from "@/views/raidmanagement/RaidCreation.vue";
import MountRaidCreation from "@/views/raidmanagement/MountRaidCreation.vue";
import ShiftCreation from "@/views/raidmanagement/ShiftCreation.vue";
import RaidEdit from "@/views/raidmanagement/RaidEdit.vue";
import MountRaidEdit from "@/views/raidmanagement/MountRaidEdit.vue";
import ShiftEdit from "@/views/raidmanagement/ShiftEdit.vue";
import RaidOverview from "@/views/booking/raid/RaidOverview.vue";
import MountRaidOverview from "@/views/booking/raid/MountRaidOverview.vue";
import ShiftOverview from "@/views/booking/raid/ShiftOverview.vue";
import RaidRecurringRuns from "@/views/raidmanagement/RaidRecurringRuns.vue";
import ProgramData from "@/views/ProgramData.vue";
import ShiftRecurringRuns from "@/views/raidmanagement/ShiftRecurringRuns.vue";
import BookingAndHistory from "@/views/booking/raid/BookingAndHistory.vue";
import MountBookingAndHistory from "@/views/booking/raid/MountBookingAndHistory.vue";
import ShiftBookingAndHistory from "@/views/booking/raid/ShiftBookingAndHistory.vue";
import StringHistory from "@/views/booking/raid/StringHistory.vue";
import CycleHistory from "@/views/booking/raid/CycleHistory.vue";
import YourRunHistory from "@/views/booking/raid/YourRunHistory.vue";
import GoldOverview from "@/views/booking/raid/GoldOverview.vue";
import ShiftGoldOverview from "@/views/booking/raid/ShiftGoldOverview.vue";
import RaidRoleOverview from "@/views/booking/raid/RaidRoleOverview.vue";
import MountRaidRoleOverview from "@/views/booking/raid/MountRaidRoleOverview.vue";
import ShiftRoleOverview from "@/views/booking/raid/ShiftRoleOverview.vue";
import RaidSchedule from "@/views/raidmanagement/RaidSchedule.vue";
import MountRaidSchedule from "@/views/raidmanagement/MountRaidSchedule.vue";
import ShiftSchedule from "@/views/raidmanagement/ShiftSchedule.vue";
import RaidCollections from "@/views/raidmanagement/RaidCollections.vue";
import AdvertiserOverview from "@/views/booking/raid/AdvertiserOverview.vue";
import ShiftAdvertiserOverview from "@/views/booking/raid/ShiftAdvertiserOverview.vue";
import CompletedCancelledOverview from "@/views/booking/raid/CompletedCancelledOverview.vue";
import ShiftCompletedCancelledOverview from "@/views/booking/raid/ShiftCompletedCancelledOverview.vue";
import ShiftHIstory from "@/views/booking/raid/ShiftHIstory.vue";
import RaidRosterList from "@/components/raidmanagement/Roster/RaidRosterList.vue";
import LogsView from "@/components/raidmanagement/Logs/LogsView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Boostee",
    component: Boostee,
  },
  {
    path: "/cycle",
    name: "Booster",
    component: Booster,
  },
  {
    path: "/loyaltysystem",
    name: "LoyaltySystem",
    component: LoyaltySystem,
  },
  {
    path: "/administration",
    name: "Administration",
    component: Administration,
  },
  {
    path: "/logging",
    name: "Logging",
    component: LogViewer,
  },
  {
    path: "/store",
    name: "Store",
    component: LoyaltyStoreForm,
  },
  {
    path: "/raid",
    name: "raid",
    component: RaidManagement,
  },
  {
    path: "/booking/raid2",
    name: "raidoverview",
    component: RaidOverviewEntry,
  },
  {
    path: "/raid/create",
    name: "CreateRaid",
    component: RaidCreation,
  },
  {
    path: "/mountraid/create",
    name: "CreateMountRaid",
    component: MountRaidCreation,
  },
  {
    path: "/raid/edit/:raidId",
    name: "EditRaid",
    component: RaidEdit,
  },
  {
    path: "/mountraid/edit/:raidId",
    name: "EditMountRaid",
    component: MountRaidEdit,
  },
  {
    path: "/raid/recurringruns",
    name: "recurringruns",
    component: RaidRecurringRuns,
  },
  {
    path: "/raid/schedule",
    name: "schedule",
    component: RaidSchedule,
  },
  {
    path: "/mountraid/schedule",
    name: "mountschedule",
    component: MountRaidSchedule,
  },
  {
    path: "/raid/gc",
    name: "collections",
    component: RaidCollections,
  },
  {
    path: "/shift/create",
    name: "CreateShift",
    component: ShiftCreation,
  },
  {
    path: "/shift/edit/:raidId",
    name: "EditShift",
    component: ShiftEdit,
  },
  {
    path: "/shift/recurringruns",
    name: "shiftrecurringruns",
    component: ShiftRecurringRuns,
  },
  {
    path: "/shift/schedule",
    name: "shiftschedule",
    component: ShiftSchedule,
  },
  {
    path: "/booking/shift/overview",
    name: "shiftOverview",
    component: ShiftOverview,
  },
  {
    path: "/booking/shift/overview/:type/:raidId?",
    name: "shiftOverviewByRole",
    component: ShiftRoleOverview,
  },
  {
    path: "/shift/goldoverview",
    name: "shiftgoldoverview",
    component: ShiftGoldOverview,
  },
  {
    path: "/shift/advoverview",
    name: "shiftadvoverview",
    component: ShiftAdvertiserOverview,
  },
  {
    path: "/shift/completed",
    name: "shiftcompletedoverview",
    component: ShiftCompletedCancelledOverview,
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking,
  },
  {
    path: "/booking/mplus",
    name: "MPlus",
    component: MythicPlus,
  },
  {
    path: "/booking/mythic",
    name: "Mythic",
    component: Mythic,
  },
  {
    path: "/booking/leveling",
    name: "Leveling",
    component: Leveling,
  },
  {
    path: "/booking/mounts",
    name: "Mounts",
    component: Mounts,
  },
  {
    path: "/booking/legacy",
    name: "Legacy",
    component: Legacy,
  },
  {
    path: "/booking/shift",
    beforeEnter() {
      location.href =
        "https://docs.google.com/spreadsheets/d/1_ypH5mOv0dAK3DaZH3LnInpvL3F8921gJYeJrm9SQXM";
    },
    // name: "Shift",
    // component: Shift,
  },
  {
    path: "/booking/pvp",
    name: "Pvp",
    component: Pvp,
  },
  {
    path: "/booking/raid",
    beforeEnter() {
      location.href =
        "https://docs.google.com/spreadsheets/d/17ShCevUmls2LEfujYZnTKLdBEuO1ATLPi0Bwc4LdyXg";
    },
    // name: "Raid",
    // component: Raid,
  },
  {
    path: "/booking/raid/overview",
    name: "raidOverview",
    component: RaidOverview,
  },
  {
    path: "/booking/mountraid/overview",
    name: "mountRaidOverview",
    component: MountRaidOverview,
  },
  {
    path: "/booking/raid/overview/:type/:raidId?",
    name: "raidOverviewByRole",
    component: RaidRoleOverview,
  },
  {
    path: "/booking/mountraid/overview/:type/:raidId?",
    name: "mountRaidOverviewByRole",
    component: MountRaidRoleOverview,
  },
  {
    path: "/booking/raid/stringhistory",
    name: "stringhistory",
    component: StringHistory,
  },
  {
    path: "/booking/raid/shifthistory",
    name: "shifthistory",
    component: ShiftHIstory,
  },
  {
    path: "/booking/raid/cyclehistory",
    name: "cyclehistory",
    component: CycleHistory,
  },
  {
    path: "/booking/raid/runhistory",
    name: "runhistory",
    component: YourRunHistory,
  },
  {
    path: "/raid/goldoverview",
    name: "goldoverview",
    component: GoldOverview,
  },
  {
    path: "/raid/advoverview",
    name: "advoverview",
    component: AdvertiserOverview,
  },
  {
    path: "/raid/completed",
    name: "completedoverview",
    component: CompletedCancelledOverview,
  },
  {
    path: "/booking/raid/bookandhistory/:raidId?",
    name: "bookandhistory",
    component: BookingAndHistory,
  },
  {
    path: "/booking/mountraid/bookandhistory/:raidId?",
    name: "mountbookandhistory",
    component: MountBookingAndHistory,
  },
  {
    path: "/booking/raid/shiftbookandhistory/:raidId?",
    name: "shiftbookandhistory",
    component: ShiftBookingAndHistory,
  },
  {
    path: "/booking/naraid",
    beforeEnter() {
      location.href =
        "https://docs.google.com/spreadsheets/d/1MGAtYAI-HvuUvoZirGcSdk7T55swXFNK67dqGqXqe24";
    },
    // name: "Raid",
    // component: Raid,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: Cookies,
  },
  {
    path: "/callback",
    name: "callback",
    component: Callback,
  },
  {
    path: "/raid/roster",
    name: "roster",
    component: RaidRosterList,
  },
  {
    path: "/raid/logs",
    name: "logs",
    component: LogsView,
  },
  {
    path: "/programmanagement",
    name: "programmanagement",
    component: ProgramData,
  },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeResolve(async (to, from, next) => {
  await store.dispatch("clearAPIInterval");
  next();
});

export default router;
