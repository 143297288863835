



























import Vue from "vue";
import RaidRosterRow from "@/components/raidmanagement/Roster/RaidRosterRow.vue";
import moment from "moment/moment";

export default Vue.extend({
  name: "RaidRosterList",
  components: { RaidRosterRow },
  data: () => ({
    rosters: [],
  }),
  methods: {
    update() {
      const isManager = this.$store.state.roles.some(
        (role) =>
          role === "RAIDMANAGER" ||
          role === "SUPERADMIN" ||
          role === "WEBACCESS"
      );
      // const isManager = true;
      if (isManager) {
        this.$store.dispatch("getRosters").then((request) => {
          this.rosters = request.data
            .map((roster) => {
              if (!roster.tank) {
                roster.tank = [];
              }
              if (!roster.healer) {
                roster.healer = [];
              }
              if (!roster.dps) {
                roster.dps = [];
              }
              if (!roster.lb) {
                roster.lb = [];
              }
              if (roster.signups) {
                Object.keys(roster.signups).forEach((boosterId) => {
                  roster.signups[boosterId].forEach((signup, index) => {
                    if (
                      !signup.role ||
                      !signup.class ||
                      (!(signup.logs || signup.logsLink) &&
                        signup.role !== "lb") ||
                      !signup.status
                    ) {
                      delete roster.signups[boosterId][index];
                    }
                  });
                  roster.signups[boosterId] = roster.signups[boosterId].filter(
                    (b) => b !== undefined
                  );
                });
              }
              return roster;
            })
            .sort((a, b) => {
              return moment(a.raid.date + " " + a.raid.time).diff(
                moment(b.raid.date + " " + b.raid.time)
              );
            });
        });
      } else {
        this.$store
          .dispatch("getOwnRosters", {
            userId: this.$store.state.user.id,
          })
          .then((request) => {
            this.rosters = request.data
              .map((roster) => {
                if (!roster.tank) {
                  roster.tank = [];
                }
                if (!roster.healer) {
                  roster.healer = [];
                }
                if (!roster.dps) {
                  roster.dps = [];
                }
                if (!roster.lb) {
                  roster.lb = [];
                }
                if (roster.signups) {
                  Object.keys(roster.signups).forEach((boosterId) => {
                    roster.signups[boosterId].forEach((signup, index) => {
                      if (
                        !signup.role ||
                        !signup.class ||
                        (!(signup.logs || signup.logsLink) &&
                          signup.role !== "lb") ||
                        !signup.status
                      ) {
                        delete roster.signups[boosterId][index];
                      }
                    });
                  });
                }
                return roster;
              })
              .sort((a, b) => {
                return moment(a.raid.date + " " + a.raid.time).diff(
                  moment(b.raid.date + " " + b.raid.time)
                );
              });
          });
      }
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.$store.dispatch("getLeaders");
    this.update();
    //     this.update();
    // }
  },
  watch: {
    "$store.state.user": {
      deep: true,
      handler: function () {
        this.update();
      },
    },
    "$store.state.roles": {
      deep: true,
      handler: function () {
        this.update();
      },
    },
  },
});
