























































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import {
  raidTypeOptions,
  typeOptions,
  raidBossOptions,
  daysOfWeek,
} from "@/shared/constants";

export default Vue.extend({
  name: "ProgramDataForm",
  components: {},
  data: () => ({
    valid: false,
    programData: [
      {
        discordID: "",
        advName: "",
        password: "",
      },
    ],
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    discordIdFilter: [] as Array<string>,
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
    arrayRequired: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && v.length > 0) || "Field is required",
    ],
  }),
  methods: {
    removeEntry(index: number) {
      this.programData.splice(index, 1);
    },
    addRaid() {
      this.programData.push({
        discordID: "",
        advName: "",
        password: "",
      });
    },
    async saveProgramData() {
      const form: any = this.$refs.form;
      this.loading = true;
      form.validate();
      const programData = {
        programData: this.programData,
      };
      const response = await this.$store.dispatch(
        "saveProgramManagementData",
        programData
      );
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "The raids has been successfully saved.",
          color: "green",
        });
      } else {
        this.snackbarToggle({
          message: "The raids couldn't be saved! Please try again later.",
          color: "red",
        });
      }
      this.loading = false;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async loadData() {
      const response = await this.$store.dispatch(
        "getProgramManagementData",
        {}
      );
      if (response.status >= 400) {
        this.$emit("snackbarToggle", {
          message: "No available data found",
          color: "red accent-1",
        });
      }
      this.programData = response.data;
    },
  },
  computed: {
    usedDiscordIDs: function (): Array<any> {
      const entries: Array<any> = this.programData;
      const uniqueIDs = new Set(entries.map((entry) => entry.discordID));
      return Array.from(uniqueIDs);
    },
    filteredData: function () {
      const entries: Array<any> = [];
      this.programData.forEach((entry: any) => {
        let filtered = true;

        if (this.discordIdFilter.length > 0 && filtered) {
          if (this.discordIdFilter.includes(entry.discordID)) filtered = true;
          else filtered = false;
        }

        if (filtered) {
          entries.push(entry);
        }
      });
      return entries;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.loadData();
    });
  },
  watch: {},
});
