























































































































































































































import {
  dayOptions,
  difficultyOptions,
  typeOptions,
  raidOptions,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import {
  Day,
  Raid,
  RaidDifficulties,
  Customer,
  RaidOption,
} from "@/interfaces/Raids";
import moment from "moment";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import RaidManagementButtons from "@/components/raidmanagement/RaidManagementButtons.vue";

export default Vue.extend({
  name: "RaidOverviewForm",
  components: { RaidManagementButtons, RaidBookingMenu },
  data: () => ({
    runs: [] as Array<Raid>,
    valid: false,
    selectedRun: {} as Raid,
    raidId: "",
    loading: false,
    snackbar: false,
    typeOptions: typeOptions,
    difficulties: difficultyOptions,
    dayOptions: dayOptions,
    raidOptions: raidOptions,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    bookingsInfoRaid: {} as Raid,
    dialog: false,
    raidTypeFilter: null,
    difficultyFilter: [] as Array<RaidDifficulties>,
    raidFilter: [] as Array<RaidOption>,
    dayFilter: [] as Array<Day>,
    availabilityFilter: false,
  }),
  methods: {
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async loadRuns() {
      this.runs = this.$store.state.raidsOverview;
    },
    dialogUp(raid: Raid) {
      this.bookingsInfoRaid = raid;
      this.dialog = true;
    },
    totalCustomers(raid: Raid) {
      return raid.totalCustomer;
    },
  },
  computed: {
    moment() {
      return moment;
    },
    filteredRaids: function () {
      const raids: Array<Raid> = [];
      this.runs.forEach((raid: Raid) => {
        let filtered = true;

        if (this.raidTypeFilter !== null && filtered) {
          if (this.raidTypeFilter === raid.type) filtered = true;
          else filtered = false;
        }

        if (this.difficultyFilter.length > 0 && filtered) {
          if (this.difficultyFilter.includes(raid.difficulty)) filtered = true;
          else filtered = false;
        }

        if (this.dayFilter.length > 0 && filtered) {
          if (this.dayFilter.includes(raid.day)) filtered = true;
          else filtered = false;
        }

        if (this.raidFilter.length > 0 && filtered) {
          if (this.raidFilter.includes(raid.raid)) filtered = true;
          else filtered = false;
        }

        if (this.availabilityFilter && filtered) {
          if (raid.Buyer.length < raid.amountOfPossibleCustomer)
            filtered = true;
          else filtered = false;
        }

        if (
          filtered &&
          raid.leader &&
          raid.leader.length > 0 &&
          !raid.completed &&
          raid.runStatus === "active" &&
          raid.runState !== "started" &&
          !raid.hidden
        ) {
          raids.push(raid);
        }
      });
      return raids.sort((a, b) => {
        const dateA = moment(a.date, "YYYY-MM-DD");
        const dateB = moment(b.date, "YYYY-MM-DD");
        const timeA = moment(a.time, "HH:mm");
        const timeB = moment(b.time, "HH:mm");

        if (dateA.isBefore(dateB)) {
          return -1;
        } else if (dateA.isAfter(dateB)) {
          return 1;
        } else {
          return timeA.diff(timeB, "milliseconds");
        }
      });
    },
  },
  mounted: function () {
    this.$store.dispatch("getLeaders");
    this.$store.dispatch("getGoldCollectors");
    this.$store.dispatch("getRaidsOverview", {
      start: moment()
        .tz("Europe/Berlin")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      end: moment().tz("Europe/Berlin").add(3, "day").format("YYYY-MM-DD"),
    });
  },
  watch: {
    "$store.state.raidsOverview": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
  },
});
