





























































































































































import AboutButton from "@/components/boostee/about/AboutButton.vue";
export default {
  name: "About",
  components: { AboutButton },
};
