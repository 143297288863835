














































































































































































































































































































































































































































































































































































































































































































































































































































import {
  factions,
  booleanOptions,
  realmOptions,
  classOptions,
  websiteAdvCutOptions,
  mailClasses,
  leatherClasses,
  clothClasses,
  WoWClass,
  bookingTypeOptions,
  typeOptions,
  runStateOptions,
  dayOptions,
  difficultyAbrevations,
  difficultyOptions,
  raidOptions,
  lootTypeAbrevations,
  advCutOptionsForCheck,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import GoldTextField from "@/components/custom/gold-text-field";
import { Raid, Customer } from "@/interfaces/Raids";
import moment, { Moment } from "moment-timezone";
import RaidBookingInformationDialog from "@/components/booking/dialogs/RaidBookingInformationDialog.vue";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import { formatPotToGold } from "@/shared/formatPotToGold";

export default Vue.extend({
  name: "MountBookingAndHistoryForm",
  components: { RaidBookingMenu, GoldTextField, RaidBookingInformationDialog },
  data: () => ({
    valid: false,
    factions: factions,
    runID: "",
    advCutOptions: websiteAdvCutOptions,
    advCut: websiteAdvCutOptions[0],
    pot: "",
    collectedGold: "",
    runs: [] as Array<Raid>,
    bookings: [],
    paymentRealm: "",
    type: Object.values(bookingTypeOptions)[0],
    typeOptions: Object.values(bookingTypeOptions),
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    booleanOptions: booleanOptions,
    note: "",
    btag: "",
    paid: false,
    bookingsInfoRaid: {} as Raid,
    buyer: "",
    difficultyFilter: "",
    raidFilter: "",
    raiderIo: "",
    raidTypeFilter: "",
    classSelected: "",
    classOptions: classOptions,
    raidTypeOptions: typeOptions,
    loading: false,
    snackbar: false,
    dialog: false,
    deleteConfirmDialog: false,
    snackbarText: "Should not show",
    deleteConfirmCustomer: {},
    deleteConfirmAction: "",
    snackbarColor: "red",
    timeout: 2000,
    bookingID: "",
    selectedRaid: {},
    dayFilter: [] as Array<string>,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    nameRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
      (v: string) =>
        (!!v && v.includes("-")) ||
        "Customer name must be in name-realm format",
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          realmOptions
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Unknown Realm selected",
    ],
    advCutRules: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          advCutOptionsForCheck
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Unknown Fee option",
    ],
    classRule: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          Object.values(WoWClass)
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Class not existing",
    ],
  }),
  methods: {
    dayOptions() {
      return dayOptions;
    },
    difficultyOptions() {
      return difficultyOptions;
    },
    raidOptions() {
      return raidOptions;
    },
    setPaymentRealm(e) {
      const value = e.target.value;
      if (value.includes("-")) {
        this.paymentRealm = value.split("-")[1];
      }
    },
    copy(val: string) {
      navigator.clipboard.writeText(val);
    },
    spot(booking: Customer) {
      const raid = this.runs.find((r) => r.id === booking.raid) || ({} as Raid);

      let bookingIndex = raid.Buyer.findIndex((c) => c.id === booking.id);
      if (bookingIndex === -1) {
        bookingIndex = raid.Backup?.findIndex((c) => c.id === booking.id) || 0;

        return (
          parseInt(raid.amountOfPossibleCustomer?.toString()) +
          (bookingIndex + 1)
        );
      } else {
        return bookingIndex + 1;
      }
    },
    formatPotToGold,
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    fireEvent() {
      if (this.deleteConfirmAction) {
        this[this.deleteConfirmAction]();
      }
    },
    calculateFee(booking: Customer): number {
      let amountOwnedPerBooking = 0;
      if (booking.advCut === "TAKEN" || booking.advCut === "FTB") {
        amountOwnedPerBooking = Number(
          (BigInt(booking.price) * BigInt(150)) / BigInt(1000)
        );
      } else {
        amountOwnedPerBooking =
          Number((BigInt(booking.price) * BigInt(100)) / BigInt(1000)) >
          BigInt(150000)
            ? 150000
            : Number((BigInt(booking.price) * BigInt(100)) / BigInt(1000));
      }
      return amountOwnedPerBooking;
    },
    moment(date?): Moment {
      return moment(date);
    },
    getFactionIcon(item: string): string {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      }
      return require("@/assets/Logo/alliance_small.png");
    },
    formatDate(date: string | Date, format: string): string {
      return moment(date).format(format);
    },
    SetIdForBooking(id: string): void {
      this.runID = id;
      Object.assign(
        this.selectedRaid,
        this.runs.find((raid: any) => raid.id === parseInt(id))
      );
    },
    bookingRaidDate(id: string): string {
      const raid: Raid = this.$store.getters.getMountRaid(id);
      const timeArray: Array<string> = raid.time.split(":");
      return this.moment(raid.date)
        .set({ hour: parseInt(timeArray[0]), minute: parseInt(timeArray[1]) })
        .format("DD.MM HH:mm");
    },
    resetForm(): void {
      const form: any = this.$refs.form;
      form.reset();

      this.advCut = "FTB";
      this.bookingID = "";
      this.runID = "";
    },
    loadRuns(): Array<Raid> {
      this.runs = this.$store.state.mountRaids || [];
      return this.runs;
    },
    async bookCustomer(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();

        const found = this.runs.find((raid: Raid) => raid.id === this.runID);
        if (found) {
          const raid: Raid = found as Raid;
          if (raid) {
            let armorType = "Plate";
            if (mailClasses.includes(this.classSelected as WoWClass))
              armorType = "Mail";
            if (leatherClasses.includes(this.classSelected as WoWClass))
              armorType = "Leather";
            if (clothClasses.includes(this.classSelected as WoWClass))
              armorType = "Cloth";

            const booking: any = {
              section: raid.difficulty,
              faction: this.paymentFaction,
              typeOfBoost: this.type,
              armorType: armorType,
              buyer: this.buyer,
              buyerClass: this.classSelected,
              paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
              paymentFaction: this.paymentFaction,
              price: parseFloat(this.pot) * 1000,
              collectedGold: this.collectedGold
                ? parseFloat(this.collectedGold) * 1000
                : 0,
              advCut: this.advCut.toUpperCase(),
              note: this.note,
              btag: this.btag,
              paid: this.paid,
              fullyPaidAtCreate: this.paid,
              raid: raid.id,
              goldWith: this.paid ? this.$store.state.user.id : "",
              customerStatus: "",
              completed: false,
            };

            const response = await this.$store.dispatch(
              "bookCustomerInMountRaid",
              {
                raidId: this.runID,
                customer: booking,
              }
            );
            if (response.status === 200) {
              this.snackbarToggle({
                message: "Your booking has been successfully created.",
                color: "green",
              });

              raid.Buyer.push(JSON.parse(response.data));
              form.reset();
            } else {
              if (response.message.includes("409")) {
                this.snackbarToggle({
                  message: "This customer already booked in this raid.",
                  color: "red",
                });
              } else {
                this.snackbarToggle({
                  message: "Your booking failed! Please try again later.",
                  color: "red",
                });
              }
            }
          }
        }
        this.loading = false;
      }
    },
    async updateBooking(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();

        const raid: Raid = this.runs.find(
          (raid: Raid) => raid.id === this.runID
        ) as unknown as Raid;

        if (raid) {
          let armorType = "Plate";
          if (mailClasses.includes(this.classSelected as WoWClass))
            armorType = "Mail";
          if (leatherClasses.includes(this.classSelected as WoWClass))
            armorType = "Leather";
          if (clothClasses.includes(this.classSelected as WoWClass))
            armorType = "Cloth";

          const bookingObject = this.allBookings.find(
            (booking: Customer) => booking.id === this.bookingID
          );

          const booking: any = {
            ...bookingObject,
            id: this.bookingID,
            section: raid.difficulty,
            faction: this.paymentFaction,
            paymentFaction: this.paymentFaction,
            typeOfBoost: this.type,
            armorType: armorType,
            buyer: this.buyer,
            buyerClass: this.classSelected,
            paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
            price: parseFloat(this.pot) * 1000,
            collectedGold: this.collectedGold
              ? parseFloat(this.collectedGold) * 1000
              : 0,
            advCut: this.advCut.toUpperCase(),
            note: this.note,
            btag: this.btag,
            paid: this.paid,
            isManager: this.$store.state.roles.some((role) => {
              return (
                role.toUpperCase() === "ADVMANAGER" ||
                role.toUpperCase() === "CERTIFIED"
              );
            }),
          };

          if (!bookingObject.paid && this.paid) {
            booking.goldWith = this.paid ? this.$store.state.user.id : "";
          }

          const response = await this.$store.dispatch("updateMountBooking", {
            booking: booking,
            raid: raid,
          });
          if (response.status >= 200 && response.status < 400) {
            this.snackbarToggle({
              message: "Your booking has been successfully updated.",
              color: "green",
            });
            this.resetForm();
          } else {
            this.snackbarToggle({
              message: "Your booking failed! Please try again later.",
              color: "red",
            });
          }
        }
        this.loading = false;
      }
    },
    async autofillCustomerInfo(): Promise<void> {
      this.loading = true;
      if (this.raiderIo) {
        const raiderIoIUrl: URL = new URL(this.raiderIo.replace(/\s+/g, ""));
        if (raiderIoIUrl.hostname === "raider.io") {
          const response = await this.$store.dispatch(
            "getRaiderIoInfo",
            this.raiderIo
          );
          if (response.status === 200) {
            this.buyer = `${response.data["name"]}-${response.data["realm"]}`;
            this.paymentRealm = response.data["realm"];
            this.paymentFaction = response.data["faction"];
            this.classSelected = response.data["class"];

            this.snackbarToggle({
              message: "Info autofilled",
              color: "green",
            });
            console.log(JSON.stringify(response.data));
          } else {
            this.snackbarToggle({
              message: "Couldn't fetch autofill data for this character",
              color: "red",
            });
          }
        } else {
          this.snackbarToggle({
            message: "no valid url for autofill",
            color: "red",
          });
        }

        this.loading = false;
      }
    },
    snackbarToggle(snackbarData: SnackbarData): void {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    loadBooking(bookingId): void {
      const booking: Customer = this.allBookings.find(
        (b) => b.id === bookingId
      );
      const found = this.runs.find((raid: Raid) => raid.id === booking.raid);
      if (found) {
        const raid: Raid = found as Raid;
        if (raid) {
          if (booking && booking.customerStatus !== "In group") {
            let wowClass: WoWClass = classOptions.find(
              (co) => co === booking.buyerClass
            ) as WoWClass;
            const paymentRealm = this.paymentRealmOptions.find(
              (realm: string) =>
                realm.replace(/\s+/g, "") === booking.paymentRealm
            );
            this.bookingID = booking.id;
            this.type = booking.typeOfBoost;
            this.buyer = booking.buyer;
            this.classSelected = wowClass;
            this.paymentRealm = paymentRealm
              ? paymentRealm
              : this.paymentRealmOptions[0];
            this.paymentFaction = booking.paymentFaction;
            this.pot = booking.price / 1000 + "";
            this.collectedGold = booking.collectedGold
              ? booking.collectedGold / 1000 + ""
              : "";
            this.advCut = booking.advCut;
            this.note = booking.note ? booking.note : "";
            this.paid = booking.paid ? booking.paid : false;
            this.runID = "" + raid.id;
          }
        }
      }
    },
    openDeleteConfirmationDialog(booking: Customer) {
      this.deleteConfirmDialog = true;
      this.deleteConfirmCustomer = booking;
      this.deleteConfirmAction = "cancelBooking";
    },
    async cancelBooking() {
      const booking: Customer = this.deleteConfirmCustomer as Customer;
      const found = this.runs.find((raid: Raid) => raid.id === booking.raid);
      if (found) {
        const raid: Raid = found as Raid;
        if (raid) {
          if (booking) {
            const response = await this.$store.dispatch("updateMountBooking", {
              booking: { ...booking, delete: true },
              raid: raid,
            });
            if (response.status >= 200 && response.status < 400) {
              this.snackbarToggle({
                message: "Your booking has been successfully cancelled.",
                color: "green",
              });
              this.deleteConfirmDialog = false;
            } else {
              this.snackbarToggle({
                message: "Your booking cancel failed! Please try again later.",
                color: "red",
              });
            }
          }
        }
      }
    },
    isDuplicate(booking: Customer): boolean {
      if (booking) {
        const raid: any = this.runs.find((r: Raid) => r.id === booking.raid);
        return (
          raid.Buyer.filter(
            (customer: Customer) =>
              customer.buyer === booking.buyer && customer.id !== booking.id
          ).length >= 1
        );
      }
      return false;
    },
    calculateOwnBuyer(raid: Raid) {
      let sum = 0;
      if (raid.Buyer) {
        const buyer = raid.Buyer as Array<Customer>;
        sum += buyer.filter((customer: Customer) => {
          return customer.advertiser?.id === this.$store.state.user.id;
        }).length;

        const backups = raid.Backup as Array<Customer>;
        sum += backups.filter((customer: Customer) => {
          return customer.advertiser?.id === this.$store.state.user.id;
        }).length;
      }
      return sum;
    },
    dialogUp(raid: Raid) {
      this.bookingsInfoRaid = raid;
      this.dialog = true;
    },
    getRaid(id: string) {
      return this.runs.find((raid: Raid) => raid.id === id) || ({} as Raid);
    },
    getAllBooking(raid: Raid): Array<Customer> {
      let buyers: Array<Customer> = raid.Buyer;
      let backups: Array<Customer> = raid.Backup as Array<Customer>;
      let merged: Array<Customer> = buyers.map((c: Customer) => {
        c.raid = raid.id;
        return c;
      });
      if (backups && backups.length > 0) {
        merged = merged.concat(
          backups.map((c: Customer) => {
            c.raid = raid.id;
            c.backup = true;
            return c;
          })
        );
      }
      return merged;
    },
    activeBuyerCount(raid: Raid) {
      let sum = 0;
      this.getAllBooking(raid).forEach((c: Customer) => {
        if (
          c.customerStatus !== "Cancelled" &&
          c.customerStatus !== "Backup" &&
          c.customerStatus !== "AFK" &&
          c.customerStatus !== "Offline" &&
          c.customerStatus !== "Duplicate"
        ) {
          sum += 1;
        }
      });
      return sum;
    },
  },
  computed: {
    lootTypeAbrevations() {
      return lootTypeAbrevations;
    },
    difficultyAbrevations() {
      return difficultyAbrevations;
    },
    runStateOptions() {
      return runStateOptions;
    },
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Faction", value: this.paymentFaction });
      values.push({ label: "FeeOption", value: this.advCut });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      values.push({
        label: "Payment Faction",
        value: this.paymentFaction,
      });
      return values;
    },
    allBookings: function () {
      const bookings: Array<any> = [];
      const userId: string = this.$store.state.user.id as string;
      this.loadRuns().forEach(function (raid: Raid) {
        let buyers: Array<Customer> = raid.Buyer.filter(
          (b: Customer) => b.advertiser?.id === userId
        );
        let backups: Array<Customer> = raid.Backup?.filter(
          (b: Customer) => b.advertiser?.id === userId
        ) as Array<Customer>;
        let merged: Array<Customer> = buyers.map((c: Customer) => {
          c.raid = raid.id;
          c.backup = false;
          return c;
        });
        if (backups && backups.length > 0) {
          merged = merged.concat(
            backups.map((c: Customer) => {
              c.raid = raid.id;
              c.backup = true;
              return c;
            })
          );
        }
        bookings.push(...merged);
      });
      return bookings.reverse();
    },
    filteredRaids: function () {
      const raids: Array<Raid> = [];
      this.loadRuns().forEach((raid: Raid) => {
        let need = true;
        if (this.difficultyFilter && need) {
          if (this.difficultyFilter === raid.difficulty) {
            need = true;
          } else {
            need = false;
          }
        }

        if (this.raidTypeFilter && need) {
          if (this.raidTypeFilter === raid.type) {
            need = true;
          } else {
            need = false;
          }
        }
        if (this.dayFilter.length > 0 && need) {
          if (this.dayFilter.includes(raid.day)) {
            need = true;
          } else {
            need = false;
          }
        }
        if (
          need &&
          raid.runStatus === "active" &&
          !raid.completed &&
          raid.leader &&
          raid.leader.length > 0
        ) {
          raids.push(raid);
        }
      });
      return raids.sort((a, b) => {
        const dateA = moment(a.date, "YYYY-MM-DD");
        const dateB = moment(b.date, "YYYY-MM-DD");
        const timeA = moment(a.time, "HH:mm");
        const timeB = moment(b.time, "HH:mm");

        if (dateA.isBefore(dateB)) {
          return -1;
        } else if (dateA.isAfter(dateB)) {
          return 1;
        } else {
          return timeA.diff(timeB, "milliseconds");
        }
      });
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$store.dispatch("getMountRaids", {
        keepToday: true,
        end: moment().add(5, "day").format("YYYY-MM-DD"),
      });
      this.$store.dispatch("getLeaders");
      this.$store.dispatch("getGoldCollectors");
      this.advCut = this.$store.state.roles.some(
        (role) => role === "TAKENBOOKER"
      )
        ? ""
        : websiteAdvCutOptions[0];
      this.advCutOptions = this.$store.state.roles.some(
        (role) => role === "TAKENBOOKER"
      )
        ? websiteAdvCutOptions
        : websiteAdvCutOptions;

      if (this.$route.params.raidId) {
        this.SetIdForBooking(this.$route.params.raidId);
      }
    });
  },
  watch: {
    advCut: function () {
      if (this.advCut) {
        let advCut = "";
        for (let i = 0; i < this.advCut.length; i++) {
          advCut += this.advCut[i];
          let found = websiteAdvCutOptions.filter((co) => {
            const advCut = co.toLowerCase().replaceAll("'", "");
            const input = advCut.toLowerCase().replaceAll("'", "");
            return advCut.includes(input);
          });
          if (found.length === 1) {
            i = this.advCut.length + 1;
            this.advCut = found[0];
          }
        }
        if (this.pot) {
          if (this.advCut.toUpperCase() === "BALANCE") {
            this.paymentRealm = "BALANCE";
            this.paymentRealmOptions = [];
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = "0";
            }
          } else if (this.advCut.toUpperCase() === "TAKEN") {
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = (
                (BigInt(this.pot) * BigInt(150)) /
                BigInt(1000)
              ).toString();
            }
          } else {
            this.paymentRealmOptions = realmOptions;
            if (this.paid) {
              this.collectedGold = this.pot;
            } else {
              this.collectedGold = "0";
            }
          }
        }
      }
    },
    paymentRealm: function () {
      if (this.paymentRealm) {
        let realm = "";
        for (let i = 0; i < this.paymentRealm.length; i++) {
          realm += this.paymentRealm[i];
          let found = realmOptions.filter((pr) => {
            const paymentRealm = pr.toLowerCase().replaceAll("'", "");
            const input = realm.toLowerCase().replaceAll("'", "");
            return paymentRealm.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentRealm.length + 1;
            this.paymentRealm = found[0];
          }
        }

        if (this.paymentRealm.toUpperCase() === "BALANCE") {
          this.advCutOptions = ["BALANCEC", "BALANCEO"];
          this.advCut = "";
        } else {
          this.advCutOptions = websiteAdvCutOptions;
        }
      }
    },
    collectedGold: function (val) {
      if (this.collectedGold && this.pot && this.advCut) {
        if (this.advCut === "TAKEN") {
          if (
            parseFloat(this.collectedGold) > parseFloat(this.pot) * 0.15 &&
            !this.paid
          ) {
            this.collectedGold = "0";
          }
        } else if (this.advCut === "FTB") {
          if (
            parseFloat(this.collectedGold) > parseFloat(this.pot) * 0.15 &&
            !this.paid
          ) {
            this.collectedGold = "0";
          } else if (this.paid) {
            this.collectedGold = "0";
          } else {
            if (val > parseFloat(this.pot) * 0.15) {
              this.collectedGold = (parseFloat(this.pot) * 0.15).toLocaleString(
                "en-US",
                { minimumIntegerDigits: 2, useGrouping: false }
              );
            } else {
              this.collectedGold = val;
            }
          }
        }
      }
    },
    pot: function () {
      if (this.pot && this.advCut.toUpperCase() === "TAKEN" && !this.paid) {
        this.$nextTick(() => {
          this.collectedGold = (parseFloat(this.pot) * 0.15).toLocaleString(
            "en-US",
            { minimumIntegerDigits: 2, useGrouping: false }
          );
        });
      }
    },
    paid: function () {
      if (this.paid) {
        this.collectedGold = this.pot;
      } else {
        if (this.pot && this.advCut.toUpperCase() === "TAKEN" && !this.paid) {
          this.$nextTick(() => {
            this.collectedGold = (parseFloat(this.pot) * 0.15).toLocaleString(
              "en-US",
              { minimumIntegerDigits: 2, useGrouping: false }
            );
          });
        } else {
          this.collectedGold = "0";
        }
      }
    },
    classSelected: function (val) {
      if (val) {
        let playerClass = "";
        for (let i = 0; i < val.length; i++) {
          playerClass += val[i];
          let found = classOptions.filter((co) => {
            const classSelected = co.toLowerCase().replaceAll("'", "");
            const input = playerClass.toLowerCase().replaceAll("'", "");
            return classSelected.includes(input);
          });
          if (found.length === 1) {
            i = this.classSelected.length + 1;
            this.classSelected = found[0];
          }
        }
      }
    },
    paymentFaction: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = factions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentFaction.length + 1;
            this.paymentFaction = found[0];
          }
        }
      }
    },
    type: function () {
      if (this.type) {
        let type = "";
        for (let i = 0; i < this.type.length; i++) {
          type += this.type[i];
          let found = typeOptions.filter((pr) => {
            const type = pr.toLowerCase().replaceAll("'", "");
            const input = type.toLowerCase().replaceAll("'", "");
            return type.includes(input);
          });
          if (found.length === 1) {
            i = this.type.length + 1;
            this.type = found[0];
          }
        }
      }
    },
    "$store.state.mountRaids": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
  },
});
