import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"position":"relative"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('p',{staticClass:"white--text text-md-h4 text-h5 pr-3"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid2"}},[_c(VIcon,{attrs:{"color":"white","size":"40"}},[_vm._v("mdi-arrow-left-thick")])],1),_vm._v(" Raid - Overview ")],1),_c('RaidBookingMenu',{attrs:{"current":"overview"}})],1)],1),_c(VDivider,{staticClass:"white"}),_c(VRow,{staticClass:"white--text py-3"},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"1"}},[_c('h3',[_vm._v("Filters:")])]),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{attrs:{"dense":"","single":"","dark":"","items":_vm.typeOptions,"outlined":"","label":"Raid Type","hide-details":"","clearable":""},model:{value:(_vm.raidTypeFilter),callback:function ($$v) {_vm.raidTypeFilter=$$v},expression:"raidTypeFilter"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{attrs:{"dense":"","multiple":"","dark":"","items":_vm.difficulties,"outlined":"","label":"Difficulty","hide-details":"","clearable":""},model:{value:(_vm.difficultyFilter),callback:function ($$v) {_vm.difficultyFilter=$$v},expression:"difficultyFilter"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{attrs:{"dense":"","multiple":"","dark":"","items":_vm.dayOptions,"outlined":"","label":"Day","hide-details":"","clearable":""},scopedSlots:_vm._u([(_vm.dayFilter.length > 2)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("Days: ("+_vm._s(_vm.dayFilter.length)+")")]):_vm._e()]}}:null],null,true),model:{value:(_vm.dayFilter),callback:function ($$v) {_vm.dayFilter=$$v},expression:"dayFilter"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{attrs:{"dense":"","multiple":"","dark":"","items":_vm.raidOptions,"outlined":"","label":"Raid","hide-details":"","clearable":""},model:{value:(_vm.raidFilter),callback:function ($$v) {_vm.raidFilter=$$v},expression:"raidFilter"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VCheckbox,{attrs:{"dense":"","dark":"","label":"Raids with free spots"},model:{value:(_vm.availabilityFilter),callback:function ($$v) {_vm.availabilityFilter=$$v},expression:"availabilityFilter"}})],1)],1),_c('div',{staticClass:"table pb-md-0 mb-10"},[_c(VRow,{staticClass:"py-md-3 px-0 mt-0 mb-3 py-1 text-center sticky-row white--text",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"1"}},[_vm._v(" Run ID ")]),_c(VCol,{attrs:{"cols":"12","md":"1"}},[_vm._v(" Date ")]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[_vm._v(" Run Type ")]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[_vm._v(" Leader ")]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[_vm._v(" Booked Slots ")]),_c(VCol,{attrs:{"cols":"8","md":"1"}},[_vm._v(" Raid ")]),_c(VCol,{attrs:{"cols":"8","md":"1"}},[_vm._v(" Note ")]),_c(VCol,{attrs:{"cols":"12","md":"5"}},[_vm._v(" Actions ")])],1),_vm._l((_vm.filteredRaids),function(run,rowIndex){return _c(VRow,{key:rowIndex,staticClass:"text-center white--text py-md-0 py-3",staticStyle:{"border-bottom":"1px solid white"},style:(run.raid === 'Blackrock Depths'
          ? 'background: rgb(194 108 73)'
          : run.difficulty.toLowerCase() === 'mythic'
          ? 'background: rgb(189, 136, 105)'
          : ''),attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(run.id)+" ")]),_c(VCol,{attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(_vm.moment(run.date).format("MM-DD"))+" "+_vm._s(run.day)+" "),_c('b',{staticClass:"light-blue--text"},[_vm._v(_vm._s((" " + (run.time) + " ")))]),_vm._v(" ST ")]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[_vm._v(" "+_vm._s(run.type)+" - "+_vm._s(run.difficulty)+" ")]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[(!run.gcDoesInvites)?_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":("discord://discord.com/users/" + (run.leader)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$store.getters.leader(run.leader).displayName)+" ")]):_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":("discord://discord.com/users/" + (run.gc)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$store.getters.goldCollector(run.gc).displayName)+" ")])]),_c(VCol,{attrs:{"cols":"4","md":"1"}},[_c('span',{style:([
            _vm.totalCustomers(run) >= run.amountOfPossibleCustomer
              ? {
                  'background-color': 'rgb(128,21,21)',
                  'border-radius': '15px',
                }
              : {},
            true ? { padding: '10px', 'font-weight': 'bold' } : {} ])},[_vm._v(" "+_vm._s(_vm.totalCustomers(run))+" / "+_vm._s(run.amountOfPossibleCustomer)+" ")])]),_c(VCol,{attrs:{"cols":"8","md":"1"}},[_vm._v(" "+_vm._s(run.raid)+" ")]),_c(VCol,{staticClass:"green--text",attrs:{"cols":"9","md":"1"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":run.note.length > 0 ? 'green' : 'grey'}},on),[_vm._v(" mdi-note ")])]}}],null,true)},[_vm._v(" Note: "+_vm._s(run.note)+" ")])],1),_c(VCol,{staticClass:"d-flex justify-space-around",attrs:{"md":"5"}},[_c('RaidManagementButtons',{attrs:{"raid":run}})],1)],1)})],2),_c(VSnackbar,{attrs:{"dark":"","centered":"","timeout":_vm.timeout,"color":_vm.snackbarColor,"min-height":"6vw","min-width":"35vw","content-class":"text-h5 pl-5"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c(VBtn,_vm._b({staticClass:"mr-5",on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),(_vm.loading)?_c('div',{staticStyle:{"width":"100%","height":"100%","top":"0","left":"0","background-color":"rgba(80, 80, 80, 0.6)","position":"absolute","flex-direction":"row"}},[_c(VRow,{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{staticClass:"justify-center align-content-center align-center",attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }