
















import ProgramDataForm from "@/components/ProgramDataForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ProgramData",
  components: { ProgramDataForm },
});
