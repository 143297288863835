import { render, staticRenderFns } from "./ProgramDataForm.vue?vue&type=template&id=2d7d6434&scoped=true&"
import script from "./ProgramDataForm.vue?vue&type=script&lang=ts&"
export * from "./ProgramDataForm.vue?vue&type=script&lang=ts&"
import style0 from "./ProgramDataForm.vue?vue&type=style&index=0&id=2d7d6434&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7d6434",
  null
  
)

export default component.exports