













































































































































































































































import {
  difficultyOptions,
  raidOptions,
  raidTypeOptions,
  typeOptions,
  raidBossOptions,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import moment from "moment/moment";
import { GoldCollector, Leader } from "@/interfaces/Raids";
import RaidManagementMenu from "@/components/booking/RaidBookingMenu.vue";

export default Vue.extend({
  name: "RaidCreationForm",
  components: { RaidManagementMenu },
  data: () => ({
    valid: false,
    date: "",
    time: null,
    type: "",
    resetFormCheckbox: false,
    raid: raidOptions[0],
    raidOptions: raidOptions,
    typeOptions: typeOptions,
    raidBoss: raidBossOptions[0],
    raidBossOptions: raidBossOptions,
    gc: "",
    leader: "",
    gcDoesInvites: false,
    goldCollectors: [] as Array<GoldCollector>,
    leaders: [] as Array<Leader>,
    difficulty: difficultyOptions[1],
    difficultyOptions: difficultyOptions,
    raidType: raidTypeOptions[0],
    raidTypeOptions: raidTypeOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    possibleCustomers: 0,
    dateRules: [(v) => !!v || "Date is required"],
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
    arrayRequired: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && v.length > 0) || "Field is required",
    ],
  }),
  methods: {
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    async createDefaultRaids() {
      const form: any = this.$refs.form;
      this.loading = true;
      form.validate();
      const response = await this.$store.dispatch("createDefaultRaids");
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "Your booking has been successfully created.",
          color: "green",
        });
        form.reset();
      } else {
        this.snackbarToggle({
          message: "Your booking failed! Please try again later.",
          color: "red",
        });
      }
      this.loading = false;
    },
    async createRaid() {
      const form: any = this.$refs.form;
      this.loading = true;
      form.validate();
      const raidDetails = {
        date: this.date,
        time: this.time,
        type: this.type,
        raid: this.raid,
        gc: this.gc,
        leader: this.leader,
        raidBoss: "0/0",
        difficulty: this.difficulty,
        raidType: this.raidType,
        createdAsRecurringRaid: false,
        amountOfRemainingCustomerToBook: this.possibleCustomers,
        amountOfPossibleCustomer: this.possibleCustomers,
        gcDoesInvites: this.gcDoesInvites,
      };
      const response = await this.$store.dispatch("createRaid", raidDetails);
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "Your raid has been successfully created.",
          color: "green",
        });
        if (this.resetFormCheckbox) {
          form.reset();
        }
      } else {
        this.snackbarToggle({
          message: "Your raid creation failed! Please try again later.",
          color: "red",
        });
      }
      this.loading = false;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
  },
  computed: {
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Type", value: this.type });

      return values;
    },
  },
  mounted: function () {
    this.$store.dispatch("getGoldCollectors");
    this.$store.dispatch("getLeaders");
    this.$nextTick(function () {
      this.date = moment().tz("Europe/Berlin").format("Y-MM-DD");
    });
  },
  watch: {
    "$store.state.goldCollectors": {
      deep: true,
      handler: function () {
        this.goldCollectors = this.$store.state.goldCollectors;
      },
    },
    "$store.state.leaders": {
      deep: true,
      handler: function () {
        this.leaders = this.$store.state.leaders;
      },
    },
  },
});
