







































import Service from "@/components/boostee/services/Service.vue";
import Vue from "vue";
export default Vue.extend({
  name: "Services",
  components: { Service },
  data: () => ({
    tab: null,
    services: [
      {
        service: "Raid",
        banner: require(`@/assets/Raid/banner.png`),
        child: {
          title: "LIBERATION OF UNDERMINE",
          subTitle:
            "Full Clear/Last Boss with Personal and VIP Loot available.",
          imgSrc: require(`@/assets/Raid/raid1.png`),
        },
        bulletPoints: [
          "Full Clear: Normal / Heroic / Mythic",
          "Full Clear PL: Personal loot run where members are saved.",
          "Full Clear LS: Loot Share run items are rolled out items and highest roller wins.",
          "Last Boss / Custom Bosses",
        ],
      },
      {
        service: "Mythic Plus & Leveling",
        banner: require(`@/assets/mplus/banner.png`),
        child: {
          title: "Mythic Plus Keys & Leveling",
          subTitle:
            "You can request any key on any Keystone Level you desire! You can also level your alts or your main!",
          imgSrc: require(`@/assets/mplus/mplus1.png`),
        },
        bulletPoints: [
          "Keystone Master",
          "Keystone Conqueror",
          "Keystone Explorer",
          "Valor Point Packages",
          "All Mythic Zero Dungeons",
          "Keystone Hero",
          "All Mythic Key Levels",
          "Leveling: From 60 to 70",
        ],
      },
      {
        service: "Legacy",
        banner: require(`@/assets/Legacy/banner.jpg`),
        child: {
          title: "Legacy Content",
          subTitle:
            "We help you with any previous Glory of the Raider Achievement",
          imgSrc: require(`@/assets/Legacy/legacy1.jpg`),
        },
        bulletPoints: [
          "Glory of the Vault Raider",
          "Glory of the Dragonflight Hero",
          "Any older Glory of the Hero/Raider",
          "Illidan and Timewalking Content",
          "Reputations, Mounts & Titles",
          "Custom Achievements & Requests",
        ],
      },
      {
        service: "PVP",
        banner: require(`@/assets/PVP/banner.png`),
        child: {
          title: "PVP 2v2 / 3v3",
          subTitle: "We will help you get to any rating in 2v2 and 3v3.",
          imgSrc: require(`@/assets/PVP/pvp1.png`),
        },
        bulletPoints: [
          "2v2 Package",
          "3v3 Package",
          "Hourly Coaching 2v2",
          "Hourly Coaching 3v3",
          "RBG (Horde only)",
          "Hero of the Horde (Horde only)",
        ],
      },
      {
        service: "Mounts",
        banner: require(`@/assets/Mounts/banner.jpeg`),
        child: {
          title: "Mounts",
          subTitle: "Any mount you desire our team can provide.",
          imgSrc: require(`@/assets/Mounts/mounts1.jpg`),
        },
        bulletPoints: [
          "Time-Lost Proto Drake",
          "Phosphorescent Stone Drake (Aeonaxx)",
          "Void Talon of the Dark Star",
          "Grey Riding Camel + Title",
          "Frightened Kodo",
          "Long Forgotten Hippogryph",
          "Any Mount Draenor Rare",
          "Smoldering Ember Wyrm (Karazhan Mount)",
          "Any spirit beast",
        ],
      },
    ],
  }),
});
