import { Boss, RunState } from "@/interfaces/Raids";
export const factions = ["Horde", "Alliance"];
export const paymentFactionOptions = ["Horde", "Alliance"];
export const legacyAdvCutOptions = ["TAKEN", "BSFTB", "FTB", "FTP", "BALANCE"];
export const extendedAdvCutOptions = ["TAKEN", "DISCORD", "FTP", "BALANCE"];
export const mplusAdvCutOptions = ["TAKEN", "FTP", "FTB", "BSFTB", "BALANCE"];
export const websiteAdvCutOptions = ["FTB", "TAKEN", "BSFTB"];
export const advCutOptionsForCheck = [
  "FTB",
  "TAKEN",
  "BSFTB",
  "BALANCEC",
  "BALANCEO",
];
export const memberCutOptions = ["WEBSITE"];
export const booleanOptions = ["Yes", "No"];
export const cycleOptions = ["Current", "Old"];
export const orderStatusOptions = ["Open", "In Preparation", "Completed"];

export const realmOptions = [
  "Balance",
  "Tarren Mill",
  "Draenor",
  "Twisting Nether",
  "Ragnaros",
  "Kazzak",
  "Stormscale",
  "Burning Legion",
  "Drak'thul",
  "Silvermoon",
  "Ravencrest",
  "Blackhand",
  "Blackrock",
  "Thrall",
  "Eredar",
  "Blackmoore",
  "Antonidas",
  "Hyjal",
  "Ysondre",
  "Ревущийфьорд",
];

// export const realmOptions = [
//   "Aegwynn",
//   "Aerie Peak",
//   "Agamaggan",
//   "Aggra",
//   "Aggramar",
//   "Ahn'Qiraj",
//   "Al'Akir",
//   "Alexstrasza",
//   "Alleria",
//   "Alonsus",
//   "Aman'thul",
//   "Ambossar",
//   "Anachronos",
//   "Anetheron",
//   "Antonidas",
//   "Anub'arak",
//   "Arak arahm",
//   "Arathi",
//   "Arathor",
//   "Archimonde",
//   "Area 52",
//   "Argent Dawn",
//   "Arthas",
//   "Arygos",
//   "Aszune",
//   "Auchindoun",
//   "AzjolNerub",
//   "Azshara",
//   "Azuremyst",
//   "Baelgun",
//   "BALANCE",
//   "Balnazzar",
//   "Blackhand",
//   "Blackmoore",
//   "Blackrock",
//   "Blade'sEdge",
//   "Bladefist",
//   "Bloodfeather",
//   "Bloodhoof",
//   "Bloodscalp",
//   "Blutkessel",
//   "Boulderfist",
//   "Bronze Dragonflight",
//   "Bronzebeard",
//   "Burning Blade",
//   "Burning Legion",
//   "Burning Steppes",
//   "C'Thun",
//   "Chamber of Aspects",
//   "Chants éternels",
//   "Cho'gall",
//   "Chromaggus",
//   "Colinas Pardas",
//   "Confrérie du Thorium",
//   "Conseil des Ombres",
//   "Crushridge",
//   "Culte de la Rive noire",
//   "Daggerspine",
//   "Dalaran",
//   "Dalvengyr",
//   "Darkmoon Faire",
//   "Darksorrow",
//   "Darkspear",
//   "Das Konsortium",
//   "Das Syndikat",
//   "Deathwing",
//   "Defias Brotherhood",
//   "Dentarg",
//   "Der Mithrilorden",
//   "Der Rat von Dalaran",
//   "Der abyssische Rat",
//   "Destromath",
//   "Dethecus",
//   "Die Aldor",
//   "Die Arguswacht",
//   "Die Nachtwache",
//   "Die Silberne Hand",
//   "Die Todeskrallen",
//   "Die ewige Wacht",
//   "Doomhammer",
//   "Draenor",
//   "Dragonblight",
//   "Dragonmaw",
//   "Drak'thul",
//   "Drek'Thar",
//   "Dun Modr",
//   "Dun Morogh",
//   "Dunemaul",
//   "Durotan",
//   "Earthen Ring",
//   "Echsenkessel",
//   "Eitrigg",
//   "Eldre'Thalas",
//   "Elune",
//   "Emerald Dream",
//   "Emeriss",
//   "Eonar",
//   "Eredar",
//   "Executus",
//   "Exodar",
//   "Festung der Stürme",
//   "Forscherliga",
//   "Frostmane",
//   "Frostmourne",
//   "Frostwhisper",
//   "Frostwolf",
//   "Garona",
//   "Garrosh",
//   "Genjuros",
//   "Ghostlands",
//   "Gilneas",
//   "Gorgonnash",
//   "Grim Batol",
//   "Gul'dan",
//   "Hakkar",
//   "Haomarush",
//   "Hellfire",
//   "Hellscream",
//   "Hyjal",
//   "Illidan",
//   "Jaedenar",
//   "Kael'thas",
//   "Karazhan",
//   "Kargath",
//   "Kazzak",
//   "Kel'Thuzad",
//   "Khadgar",
//   "Khaz Modan",
//   "Khaz'goroth",
//   "Kil'jaeden",
//   "Kilrogg",
//   "Kirin Tor",
//   "Kor'gall",
//   "Krag'jin",
//   "Krasus",
//   "Kul Tiras",
//   "Kult der Verdammten",
//   "La Croisade écarlate",
//   "Laughing Skull",
//   "Les Clairvoyants",
//   "Les Sentinelles",
//   "Lightbringer",
//   "Lightning's Blade",
//   "Lordaeron",
//   "Los Errantes",
//   "Lothar",
//   "Madmortem",
//   "Magtheridon",
//   "Mal'Ganis",
//   "Malfurion",
//   "Malorne",
//   "Malygos",
//   "Mannoroth",
//   "Marécage de Zangar",
//   "Mazrigos",
//   "Medivh",
//   "Minahonda",
//   "Moonglade",
//   "Mug'thol",
//   "Nagrand",
//   "Nathrezim",
//   "Naxxramas",
//   "Nazjatar",
//   "Nefarian",
//   "Nemesis",
//   "Neptulon",
//   "Ner'zhul",
//   "Nera'thor",
//   "Nethersturm",
//   "Nordrassil",
//   "Norgannon",
//   "Nozdormu",
//   "Onyxia",
//   "Outland",
//   "Perenolde",
//   "Pozzo dell'Eternità",
//   "Proudmoore",
//   "Quel'Thalas",
//   "Ragnaros",
//   "Rajaxx",
//   "Rashgarroth",
//   "Ravencrest",
//   "Ravenholdt",
//   "Rexxar",
//   "Runetotem",
//   "Sanguino",
//   "Sargeras",
//   "Saurfang",
//   "Scarshield Legion",
//   "Sen'jin",
//   "Shadowsong",
//   "Shattered Halls",
//   "Shattered Hand",
//   "Shattrath",
//   "Shen'dralar",
//   "Silvermoon",
//   "Sinstralis",
//   "Skullcrusher",
//   "Spinebreaker",
//   "Sporeggar",
//   "Steamwheedle Cartel",
//   "Stormrage",
//   "Stormreaver",
//   "Stormscale",
//   "Sunstrider",
//   "Suramar",
//   "Sylvanas",
//   "Taerar",
//   "Talnivarr",
//   "Tarren Mill",
//   "Teldrassil",
//   "Temple noir",
//   "Terenas",
//   "Terokkar",
//   "Terrordar",
//   "The Maelstrom",
//   "The Sha'tar",
//   "TheVenture Co",
//   "Theradras",
//   "Thrall",
//   "Throk'Feroth",
//   "Thunderhorn",
//   "Tichondrius",
//   "Tirion",
//   "Todeswache",
//   "Trollbane",
//   "Turalyon",
//   "Twilight'sHammer",
//   "Twisting Nether",
//   "Tyrande",
//   "Uldaman",
//   "Ulduar",
//   "Uldum",
//   "Un'Goro",
//   "Varimathras",
//   "Vashj",
//   "Vek'lor",
//   "Vek'nilash",
//   "Vol'jin",
//   "Wildhammer",
//   "Wrathbringer",
//   "Xavius",
//   "Ysera",
//   "Ysondre",
//   "Zenedar",
//   "Zirkel des Cenarius",
//   "Zul'jin",
//   "Zuluhed",
//   "ЧерныйШрам",
//   "Борейскаятундра",
//   "Термоштепсель",
//   "Гром",
//   "ТкачСмерти",
//   "Пиратскаябухта",
//   "Ревущийфьорд",
//   "Азурегос",
//   "ВечнаяПесня",
//   "Дракономор",
//   "Галакронд",
//   "Подземье",
//   "Разувий",
//   "Голдринн",
//   "Король-лич",
//   "Седогрив",
//   "Гордунни",
//   "СвежевательДуш",
//   "СтражСмерти",
//   "Ясеневый лес",
// ];

export const amountOfKeysOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];
export const keystoneLevelOptions = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
];
export const armorStackOptions = [
  "No",
  "Leather",
  "Cloth",
  "Plate",
  "Mail",
  "Weapon",
  "Trinket",
];
export const dungeonOptions = [
  "Random Dungeon",
  "Ara-Kara, City of Echoes",
  "City of Threads",
  "The Stonevault",
  "The Dawnbreaker",
  "Mists of Tirna Scithe",
  "The Necrotic Wake",
  "Siege of Boralus",
  "Grim Batol",
  "World Tour",
  "Half Worldtour",
];

export const dungeonAbrevations = {
  "Random Dungeon": "RD",
  "Ara-Kara, City of Echoes": "ARA",
  "City of Threads": "CoT",
  "The Stonevault": "SV",
  "The Dawnbreaker": "DB",
  "Mists of Tirna Scithe": "MoTS",
  "The Necrotic Wake": "NW",
  "Siege of Boralus": "SoB",
  "Grim Batol": "GB",
  "World Tour": "WT",
  "Half Worldtour": "HWT",
};

export const levelOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
];

export enum WoWClass {
  "DeathKnight" = "Death Knight",
  "DemonHunter" = "Demon Hunter",
  "Druid" = "Druid",
  "Hunter" = "Hunter",
  "Mage" = "Mage",
  "Monk" = "Monk",
  "Paladin" = "Paladin",
  "Priest" = "Priest",
  "Rogue" = "Rogue",
  "Shaman" = "Shaman",
  "Warlock" = "Warlock",
  "Warrior" = "Warrior",
  "Evoker" = "Evoker",
}

export const myticTokenClasses = [
  WoWClass.Hunter,
  WoWClass.Mage,
  WoWClass.Druid,
];
export const dreadfulTokenClasses = [
  WoWClass.DeathKnight,
  WoWClass.Warlock,
  WoWClass.DemonHunter,
];
export const veneratedTokenClasses = [
  WoWClass.Paladin,
  WoWClass.Priest,
  WoWClass.Shaman,
];
export const zenithTokenClasses = [
  WoWClass.Warrior,
  WoWClass.Rogue,
  WoWClass.Monk,
  WoWClass.Evoker,
];

export const classOptions: WoWClass[] = [
  WoWClass.DeathKnight,
  WoWClass.DemonHunter,
  WoWClass.Druid,
  WoWClass.Hunter,
  WoWClass.Mage,
  WoWClass.Monk,
  WoWClass.Paladin,
  WoWClass.Priest,
  WoWClass.Rogue,
  WoWClass.Shaman,
  WoWClass.Warlock,
  WoWClass.Warrior,
  WoWClass.Evoker,
];

export const plateClasses = [
  WoWClass.Paladin,
  WoWClass.Warrior,
  WoWClass.DeathKnight,
];
export const mailClasses = [WoWClass.Hunter, WoWClass.Shaman, WoWClass.Evoker];
export const leatherClasses = [
  WoWClass.DemonHunter,
  WoWClass.Druid,
  WoWClass.Rogue,
  WoWClass.Monk,
];
export const clothClasses = [WoWClass.Mage, WoWClass.Priest, WoWClass.Warlock];

export const dayOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
export const raidOptions = [
  "Liberation of Undermine",
  "Nerub-ar Palace",
  "Blackrock Depths",
];
export const raidTypeOptions = ["In-House", "Guild", "Team"];
export const raidBossOptions = [
  "Full Run",
  "7/8",
  "6/8",
  "5/8",
  "4/8",
  "Custom",
];
export const difficultyOptions = ["Normal", "Heroic", "Mythic"];
export const difficultyAbrevations = {
  Normal: "NM",
  Heroic: "HC",
  Mythic: "M",
};
export const shiftDifficultyOptions = ["Normal", "Heroic"];
export const shiftOptions = ["TEAM1_H", "TEAM2_H", "TEAM3_H"];
export const pvpServiceOptions = ["Rating", "Coaching", "Wins"];
export const categoryOptions = ["2s", "3s", "RBG"];
export const coachingCategoryOptions = ["2s", "3s"];
export const typeOptions = ["Saved", "Lootshare", "VIP", "MOUNT"];
export const bookingTypeOptions = {
  full: "Full Run",
  notLast: "7/8",
  "not last two": "6/8",
  "not last three": "5/8",
  custom: "Custom",
  vip: "VIP",
};
export const shiftBossOptions: Array<Boss> = ["MugZee", "Gallywix", "last two"];
export const bossAbrevations = {
  Kazzara: "Kaz",
  Molgoth: "Mol",
  "Experimentation of Dracthyr": "EoD",
  "Zaqali Invasion": "ZI",
  Rashok: "Rsk",
  Zskarn: "Zsk",
  Magmorax: "Mag",
  "Echo of Neltharion": "EoN",
  "Scalecommander Sarkareth": "ScS",
  "last two": "LT",
};
export const mountOptions = [
  "Voidtalon of the Dark Star",
  "Fabious",
  "Time-Lost- Proto Drake",
  "Phosphorescent Stone Drake (Aeonaxx)",
  "Elusive Quickhoof",
  "Grey Riding Camel + Title & Achievement",
  "Ivory Cloud Serpent",
  "Frightened Kodo",
  "Long-Forgotten Hippogryph",
  "Draenor Rare Mount",
  "Inkscale Deepseeker",
  "Ivory Hawkstrider",
  "Hivemind",
  "Crimson Tidestallion",
  "Springfur Alpaca",
  "Smoldering Ember Wyrm (Nightbane)",
  "Ashhide Mushan Beast + Fire Watcher Title",
  "Silverwind Larion",
  "Swift Gloomhoof",
  "Spinemaw Gladechewer",
  "Shimmermist Runner",
  "Arboreal Gulper",
  "Wildseed Cradle",
  "Garn Steelmaw",
  "Shadowhide Pearltusk",
  "Giant Coldsnout",
  "Smoky Direwolf",
  "Amber Direhorn",
  "Jade Direhorn",
  "Slate Primordal Direhorn",
  "Armored Razorback",
  "Tundra Icehoof",
  "Warsong Direfang",
  "Sea Turtle",
  "Riding Turtle",
  "Any Hunter Pet",
  "Netherwing Rep (6 mounts)",
  "Pandaria Rep Bundle (8 mounts)",
  "Cloud Serpent Rep (3 mounts)",
  "Bloody Rare Achievement (Full Achievement)",
  "Bloody Rare Achievement (Per Rare)",
  "Frostbitten Achievement (Full Achievement)",
  "Frostbitten Achievement (Loque'nahak/Vyragosa)",
  "Frostbitten Achievement (Per Rare)",
  "Timeless Champion Achievement (Full Achievement)",
  "Timeless Champion Achievement (Imperial Python & Monstrous Spineclaw)",
  "Timeless Champion Achievement (Per Rare)",
  "Higher Learning Achievement",
  "Arctic Fox Kit",
  "Baby Ape",
  "Silithid Hatchling",
  "Unborn Val'kyr",
  "Vicious Broodling",
  "Odd Polished Stone",
  "Warning Sign",
  "Eternal Kiln",
  "Blackflame Daggers",
  "Hardened Shell",
  "Ash-Covered Horn",
  "Ai-Li's Skymirror",
  "Ancient Pandaren Fishing Charm",
  "Aqua Jewel",
  "Battle Horn",
  "Bottled Tornado",
  "Burgy Blackheart",
  "Eternal Warrior's Sigil",
  "Farwater Conch",
  "Helpful Wikky's Whistle",
  "Hozen Beach Ball",
  "Kang's Bindstone",
  "Mr. Smite's Brass Compass",
  "Pandaren Firework Launcher",
  "Panflute of Pandaria",
  "Salyin Battle Banner",
  "Shard of Archstone",
];

export const specOptions = [
  "Affliction",
  "Arcance",
  "Arms",
  "Assassination",
  "Balance",
  "Beast Mastery",
  "Blood",
  "Brewmaster",
  "Demonology",
  "Destruction",
  "Devastation",
  "Discipline",
  "Elemental",
  "Enhancement",
  "Feral",
  "Fire",
  "Frost",
  "Fury",
  "Guardian",
  "Havoc",
  "Holy",
  "Marksmanship",
  "Mistweaver",
  "Preservation",
  "Protection",
  "Restoration",
  "Retribution",
  "Shadow",
  "Subtlety",
  "Survival",
  "Outlaw",
  "Unholy",
  "Vengeance",
  "Windwalker",
];

export const paymentStatuses = {
  paid: "Paid",
  unpaid: "Unpaid",
  all: "All",
};

export const customerStatuses = ["completed"];

export const customerGroupStatuses = [
  "In group",
  "AFK",
  "Cancelled",
  "Offline",
  "Duplicate",
  "Backup",
  "Run full - No Spot",
];

export const mailedOptions = [
  { value: true, text: "Mailed" },
  { value: false, text: "Unmailed" },
];

export const runStateOptions: Array<RunState> = [
  { value: "starting", text: "Run start in 15min" },
  { value: "started", text: "Run started" },
  { value: "delayed", text: "Run delayed 15min" },
];

export const lootTypeAbrevations = {
  Saved: "SD",
  Lootshare: "LS",
  VIP: "VIP",
  MOUNT: "MOUNT",
};

export const rosterClasses = {
  mage: WoWClass.Mage,
  hunter: WoWClass.Hunter,
  druid: WoWClass.Druid,
  shaman: WoWClass.Shaman,
  paladin: WoWClass.Paladin,
  priest: WoWClass.Priest,
  warrior: WoWClass.Warrior,
  deathKnight: WoWClass.DeathKnight,
  "death knight": WoWClass.DeathKnight,
  "demon hunter": WoWClass.DemonHunter,
  evoker: WoWClass.Evoker,
  demonHunter: WoWClass.DemonHunter,
  demonhunter: WoWClass.DemonHunter,
  deathknight: WoWClass.DeathKnight,
  warlock: WoWClass.Warlock,
  monk: WoWClass.Monk,
  rogue: WoWClass.Rogue,
};
